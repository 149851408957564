define("skylines/helpers/initials", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initials = initials;
  _exports.default = void 0;

  function initials(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        name = _ref2[0];

    var parts = name.split(/\s/);
    var initials = parts.filter(function (it) {
      return it.length > 2 && it.indexOf('.') === -1;
    }).map(function (it) {
      return it[0].toUpperCase();
    });
    return initials.join('');
  }

  var _default = Ember.Helper.helper(initials);

  _exports.default = _default;
});