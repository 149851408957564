define("skylines/helpers/replace-str", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.replaceSub = replaceSub;
  _exports.default = void 0;

  function replaceSub(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 3),
        str = _ref2[0],
        subStr = _ref2[1],
        repStr = _ref2[2];

    return str.replace(subStr, repStr);
  }

  var _default = Ember.Helper.helper(replaceSub);

  _exports.default = _default;
});