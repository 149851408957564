define("skylines/templates/components/cesium-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g/mjDY/8",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"enabled\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\",true],[10,\"src\",\"../../images/2d.png\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"img\",true],[10,\"src\",\"../../images/3d.png\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/cesium-button.hbs"
    }
  });

  _exports.default = _default;
});