define("skylines/templates/components/takeoffs-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eUbfJdLi",
    "block": "{\"symbols\":[],\"statements\":[[5,\"layer-switcher\",[],[[\"@map\"],[[22,\"map\"]]]],[0,\"\\n\"],[5,\"takeoffs-layer\",[],[[\"@map\",\"@locations\"],[[22,\"map\"],[22,\"locations\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/takeoffs-map.hbs"
    }
  });

  _exports.default = _default;
});