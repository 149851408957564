define("skylines/templates/groupflight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "REnoOkOj",
    "block": "{\"symbols\":[],\"statements\":[[5,\"base-page\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/groupflight.hbs"
    }
  });

  _exports.default = _default;
});