define("skylines/helpers/markdown", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "remarkable"], function (_exports, _slicedToArray2, _remarkable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.markdown = markdown;
  _exports.default = void 0;
  var remarkable = new _remarkable.default();

  function markdown(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        text = _ref2[0];

    return Ember.String.htmlSafe(remarkable.render(text));
  }

  var _default = Ember.Helper.helper(markdown);

  _exports.default = _default;
});