define("skylines/routes/login", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    setupController: function setupController() {
      this._super.apply(this, arguments);

      this.controllerFor('application').set('inLoginRoute', true);
    },
    resetController: function resetController() {
      this._super.apply(this, arguments);

      this.controllerFor('application').set('inLoginRoute', false);
    }
  });

  _exports.default = _default;
});