define("skylines/templates/components/recover-step2-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mj9IuIB/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\",false],[3,\"action\",[[23,0,[]],\"submit\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[5,\"bs-alert\",[],[[\"@type\",\"@dismissible\"],[\"danger\",false]],{\"statements\":[[1,[22,\"error\"],false]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"success\"]]],null,{\"statements\":[[0,\"    \"],[5,\"bs-alert\",[],[[\"@type\",\"@dismissible\"],[\"success\",false]],{\"statements\":[[1,[28,\"t\",[\"password-recovered\"],null],false]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"validated-input\",[],[[\"@type\",\"@value\",\"@validation\",\"@label\",\"@disabled\",\"@didValidate\"],[\"password\",[22,\"password\"],[24,[\"validations\",\"attrs\",\"password\"]],[28,\"t\",[\"password\"],null],[24,[\"recoverTask\",\"isRunning\"]],[22,\"didValidate\"]]]],[0,\"\\n\\n  \"],[5,\"validated-input\",[],[[\"@type\",\"@value\",\"@validation\",\"@label\",\"@disabled\",\"@didValidate\"],[\"password\",[22,\"passwordConfirmation\"],[24,[\"validations\",\"attrs\",\"passwordConfirmation\"]],[28,\"t\",[\"confirm-password\"],null],[24,[\"recoverTask\",\"isRunning\"]],[22,\"didValidate\"]]]],[0,\"\\n\\n  \"],[7,\"button\",true],[10,\"class\",\"btn btn-primary btn-block\"],[11,\"disabled\",[28,\"or\",[[24,[\"recoverTask\",\"isRunning\"]],[24,[\"success\"]],[28,\"not\",[[24,[\"validations\",\"isValid\"]]],null]],null]],[10,\"type\",\"submit\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[[28,\"if\",[[24,[\"recoverTask\",\"isRunning\"]],\"changing-password\",\"change-password\"],null]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/recover-step2-form.hbs"
    }
  });

  _exports.default = _default;
});