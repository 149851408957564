define("skylines/templates/components/fix-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B34aJqKH",
    "block": "{\"symbols\":[\"row\"],\"statements\":[[7,\"table\",true],[10,\"id\",\"fix-data\"],[10,\"class\",\"table table-condensed\"],[8],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"data\"]]],null,{\"statements\":[[0,\"      \"],[5,\"fix-table-row\",[],[[\"@row\",\"@selectable\",\"@selected\",\"@onSelect\",\"@onRemove\"],[[23,1,[]],[22,\"selectable\"],[28,\"and\",[[24,[\"selectable\"]],[28,\"eq\",[[23,1,[\"id\"]],[24,[\"selection\"]]],null]],null],[28,\"action\",[[23,0,[]],\"select\"],null],[22,\"onRemove\"]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/fix-table.hbs"
    }
  });

  _exports.default = _default;
});