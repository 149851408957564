define("skylines/components/timeline-events/-base", ["exports", "skylines/computed/safe-computed"], function (_exports, _safeComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    account: Ember.inject.service(),
    tagName: 'tr',
    event: null,
    accountUserIsActor: (0, _safeComputed.default)('account.user', 'event.actor', function (accountUser, actor) {
      return accountUser.id === actor.id;
    })
  });

  _exports.default = _default;
});