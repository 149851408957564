define("skylines/templates/components/wingman-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I6KeI7J+",
    "block": "{\"symbols\":[\"nearFlight\"],\"statements\":[[7,\"table\",true],[10,\"id\",\"wingman-table\"],[10,\"class\",\"table table-condensed\"],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[10,\"class\",\"color-stripe\"],[8],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[1,[28,\"t\",[\"aircraft\"],null],false],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[1,[28,\"t\",[\"pilot\"],null],false],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[1,[28,\"t\",[\"first-last\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"nearFlightsWithColors\"]]],null,{\"statements\":[[0,\"      \"],[5,\"wingman-row\",[],[[\"@nearFlight\",\"@click\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"select\",[23,1,[\"flight\",\"id\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/wingman-table.hbs"
    }
  });

  _exports.default = _default;
});