define("skylines/templates/components/change-aircraft-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XsJasAKR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\",false],[3,\"action\",[[23,0,[]],\"submit\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[5,\"bs-alert\",[],[[\"@type\",\"@dismissible\"],[\"danger\",false]],{\"statements\":[[1,[22,\"error\"],false]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"control-label\"],[8],[1,[28,\"t\",[\"aircraft-type\"],null],false],[9],[0,\"\\n    \"],[5,\"aircraft-model-select\",[],[[\"@models\",\"@modelId\",\"@onChange\"],[[22,\"models\"],[22,\"modelId\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"modelId\"]]],null]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[5,\"validated-input\",[],[[\"@value\",\"@validation\",\"@label\",\"@disabled\",\"@didValidate\"],[[22,\"registration\"],[24,[\"validations\",\"attrs\",\"registration\"]],[28,\"t\",[\"registration\"],null],[24,[\"saveTask\",\"isRunning\"]],[22,\"didValidate\"]]]],[0,\"\\n\\n  \"],[5,\"validated-input\",[],[[\"@value\",\"@validation\",\"@label\",\"@disabled\",\"@didValidate\"],[[22,\"competitionId\"],[24,[\"validations\",\"attrs\",\"competitionId\"]],[28,\"t\",[\"competition-id\"],null],[24,[\"saveTask\",\"isRunning\"]],[22,\"didValidate\"]]]],[0,\"\\n\\n  \"],[7,\"button\",true],[10,\"class\",\"btn btn-primary btn-block\"],[11,\"disabled\",[28,\"or\",[[24,[\"saveTask\",\"isRunning\"]],[28,\"not\",[[24,[\"validations\",\"isValid\"]]],null]],null]],[10,\"type\",\"submit\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[[28,\"if\",[[24,[\"saveTask\",\"isRunning\"]],\"changing\",\"change\"],null]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/change-aircraft-form.hbs"
    }
  });

  _exports.default = _default;
});