define("skylines/routes/flight/change-pilot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    account: Ember.inject.service(),
    model: function model() {
      var id = this.modelFor('flight').ids[0];
      var flight = this.ajax.request("/api/flights/".concat(id, "/")).then(function (it) {
        return it.flight;
      });
      var accountId = this.get('account.user.id');
      var clubId = this.get('account.club.id');
      var clubMembers = clubId ? this.ajax.request("/api/users?club=".concat(clubId)).then(function (it) {
        return it.users.filter(function (user) {
          return user.id !== accountId;
        });
      }) : [];
      return Ember.RSVP.hash({
        id: id,
        flight: flight,
        clubMembers: clubMembers
      });
    }
  });

  _exports.default = _default;
});