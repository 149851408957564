define("skylines/templates/components/club-edit-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SX9mbXmw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\",false],[3,\"action\",[[23,0,[]],\"submit\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[5,\"bs-alert\",[],[[\"@type\",\"@dismissible\"],[\"danger\",false]],{\"statements\":[[1,[22,\"error\"],false]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[5,\"validated-input\",[],[[\"@value\",\"@validation\",\"@label\",\"@disabled\",\"@didValidate\"],[[22,\"name\"],[24,[\"validations\",\"attrs\",\"name\"]],[28,\"t\",[\"name\"],null],[24,[\"saveTask\",\"isRunning\"]],[22,\"didValidate\"]]]],[0,\"\\n\\n   \"],[5,\"validated-input\",[],[[\"@value\",\"@validation\",\"@label\",\"@autocomplete\",\"@didValidate\"],[[22,\"email\"],[24,[\"validations\",\"attrs\",\"email\"]],[28,\"t\",[\"club-contact-email-address\"],null],\"email\",[22,\"didValidate\"]]]],[0,\"\\n\\n  \"],[5,\"validated-input\",[],[[\"@value\",\"@validation\",\"@label\",\"@disabled\",\"@autocomplete\",\"@didValidate\"],[[22,\"website\"],[24,[\"validations\",\"attrs\",\"website\"]],[28,\"t\",[\"website-url\"],null],[24,[\"saveTask\",\"isRunning\"]],\"optional\",[22,\"didValidate\"]]]],[0,\"\\n\\n  \"],[7,\"button\",true],[10,\"class\",\"btn btn-primary btn-block\"],[11,\"disabled\",[28,\"or\",[[24,[\"saveTask\",\"isRunning\"]],[28,\"not\",[[24,[\"validations\",\"isValid\"]]],null]],null]],[10,\"type\",\"submit\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[[28,\"if\",[[24,[\"saveTask\",\"isRunning\"]],\"editing\",\"edit\"],null]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/club-edit-form.hbs"
    }
  });

  _exports.default = _default;
});