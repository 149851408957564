define("skylines/components/base-barogram", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    units: Ember.inject.service(),
    layoutName: 'components/base-barogram',
    height: 133,
    flot: null,
    active: null,
    passive: null,
    enls: null,
    contests: null,
    elevations: null,
    flotStyle: Ember.computed('height', function () {
      if (this.height) {
        return Ember.String.htmlSafe("width: 100%; height: ".concat(this.height, "px;"));
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var units = this.units;
      var opts = {
        grid: {
          borderWidth: 0,
          hoverable: true,
          clickable: true,
          autoHighlight: false,
          margin: 5
        },
        xaxis: {
          mode: 'time',
          timeformat: '%H:%M'
        },
        yaxes: [{
          min: 0,
          tickFormatter: units.addAltitudeUnit.bind(units)
        }, {
          show: false,
          min: 0,
          max: 1000
        }],
        crosshair: {
          mode: 'x'
        }
      };

      if (this.uploadMode) {
        opts.selection = {
          mode: 'x'
        };
        opts.crosshair = {
          mode: null
        };
      }

      var placeholder = (0, _jquery.default)(this.element.querySelector('div'));
      this.set('placeholder', placeholder);
      this.set('flot', _jquery.default.plot(placeholder, [], opts));
    },
    draw: function draw() {
      this.update();
      var flot = this.flot;
      flot.setupGrid();
      flot.draw();
    },
    update: function update() {
      var data = [];
      this.addElevations(data);
      data = data.concat(this.activeTraces());
      data = data.concat(this.passiveTraces());
      data = data.concat(this.enlData());
      this.addContests(data);
      this.flot.setData(data);
    },
    activeTraces: function activeTraces() {
      return this.active.map(function (trace) {
        return {
          data: trace.data,
          color: trace.color
        };
      });
    },
    passiveTraces: function passiveTraces() {
      return (this.passive || []).map(function (trace) {
        return {
          data: trace.data,
          color: _jquery.default.color.parse(trace.color).add('a', -0.6).toString(),
          shadowSize: 0,
          lines: {
            lineWidth: 1
          }
        };
      });
    },
    enlData: function enlData() {
      return this.enls.map(function (enl) {
        return {
          data: enl.data,
          color: enl.color,
          lines: {
            lineWidth: 0,
            fill: 0.2
          },
          yaxis: 2
        };
      });
    },
    addContests: function addContests(data) {
      // Skip the function if there are no contest markers
      var contests = this.contests;

      if (!contests) {
        return;
      } // Iterate through the contests


      contests.forEach(function (contest) {
        var times = contest.get('times');

        if (times.length < 1) {
          return;
        }

        var color = contest.get('color'); // Add the turnpoint markers to the markings array

        var markings = times.map(function (time) {
          return {
            position: time * 1000
          };
        }); // Add the chart series for this contest to the data array

        data.push({
          marks: {
            show: true,
            lineWidth: 1,
            toothSize: 6,
            color: color,
            fillColor: color
          },
          data: [],
          markdata: markings
        });
      });
    },
    addElevations: function addElevations(data) {
      data.push({
        data: this.elevations,
        color: 'rgb(235, 155, 98)',
        lines: {
          lineWidth: 0,
          fill: 0.8
        }
      });
    }
  });

  _exports.default = _default;
});