define("skylines/templates/user/following", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rnTtwmlt",
    "block": "{\"symbols\":[],\"statements\":[[5,\"following-page\",[],[[\"@user\",\"@following\"],[[22,\"user\"],[22,\"following\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/user/following.hbs"
    }
  });

  _exports.default = _default;
});