define("skylines/templates/components/form-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rl5WxuQl",
    "block": "{\"symbols\":[\"@title\",\"&default\"],\"statements\":[[5,\"base-page\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"page-header\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[1,[23,1,[]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-8 col-sm-offset-2\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"panel panel-default\"],[8],[0,\"\\n        \"],[14,2],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/form-page.hbs"
    }
  });

  _exports.default = _default;
});