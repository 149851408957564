define("skylines/components/user-quick-stats", ["exports", "skylines/computed/safe-computed"], function (_exports, _safeComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    speed: (0, _safeComputed.default)('stats.distance', 'stats.duration', function (distance, duration) {
      return distance / duration;
    }),
    avgDistance: (0, _safeComputed.default)('stats.distance', 'stats.flights', function (distance, flights) {
      return distance / flights;
    }),
    avgDuration: (0, _safeComputed.default)('stats.duration', 'stats.flights', function (duration, flights) {
      return duration / flights;
    })
  });

  _exports.default = _default;
});