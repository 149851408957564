define("skylines/routes/groupflights/pinned", ["exports", "skylines/routes/groupflights/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    pinnedFlights: Ember.inject.service(),
    model: function model(params) {
      var pinned = this.get('pinnedFlights.pinned') || [];

      if (pinned.length === 0) {
        return {
          count: 0,
          flights: []
        };
      }

      return this.ajax.request("/api/groupflights/list/".concat(pinned.join(',')), {
        data: {
          page: params.page,
          column: params.column,
          order: params.order
        }
      });
    }
  });

  _exports.default = _default;
});