define("skylines/components/pilot-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    account: Ember.inject.service(),
    tagName: '',
    clubMembers: null,
    pilotId: null,
    pilots: Ember.computed('account.{user,club}', 'clubMembers.[]', function () {
      var user = this.get('account.user');
      var club = this.get('account.club');
      var clubMembers = this.clubMembers;
      var pilots = [{
        id: null
      }, user];

      if (club && clubMembers) {
        pilots.push({
          groupName: Ember.get(club, 'name'),
          options: clubMembers
        });
      }

      return pilots;
    }),
    pilot: Ember.computed('pilotsWithNull.[]', 'pilotId', function () {
      return this.findPilot(this.pilotId || null);
    }),
    actions: {
      onChange: function onChange(pilot) {
        this.onChange(pilot.id);
      }
    },
    findPilot: function findPilot(id) {
      var pilots = this.pilots;
      var pilot = pilots.findBy('id', id);

      if (pilot) {
        return pilot;
      }

      var clubMembers = pilots.get('lastObject.options');

      if (clubMembers) {
        return clubMembers.findBy('id', id);
      }
    }
  });

  _exports.default = _default;
});