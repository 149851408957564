define("skylines/helpers/format-seconds", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "skylines/utils/pad"], function (_exports, _slicedToArray2, _pad) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatSeconds = formatSeconds;
  _exports.default = void 0;

  function formatSeconds(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        value = _ref2[0];

    value %= 86400;
    var h = Math.floor(value / 3600);
    var m = Math.floor(value % 3600 / 60);
    var s = Math.floor(value % 3600 % 60); // Format the result into time strings

    return "".concat(h, ":").concat((0, _pad.default)(m), ":").concat((0, _pad.default)(s));
  }

  var _default = Ember.Helper.helper(formatSeconds);

  _exports.default = _default;
});