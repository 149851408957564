define("skylines/utils/map-click-handler", ["exports", "openlayers"], function (_exports, _openlayers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = slMapClickHandler;
  var MapClickHandler = Ember.Object.extend({
    /**
     * The OpenLayers.Geometry object of the circle.
     * @type {Object}
     */
    circle: null,

    /**
     * Stores the state if the infobox.
     * @type {Boolean}
     */
    visible: false,
    infobox: null,
    init: function init() {
      var _this = this;

      this.circle = {
        geometry: null,
        animation: null
      };
      this.map.on('click', function (event) {
        return _this.trigger(event);
      });
    },
    // Public attributes and functions

    /**
     * Click handler which shows a info box at the click location.
     *
     * @this {ol.Map}
     * @param {Event} event
     * @return {(boolean|undefined)}
     */
    trigger: function trigger(event) {
      // Hide infobox if it's currently visible
      if (this.visible) {
        event.map.removeOverlay(this.infobox);
        this.hideCircle(0);
        this.setProperties({
          visible: false,
          infobox: null
        });
        return;
      }

      if (!this.infobox) {
        this.set('infobox', new _openlayers.default.Overlay({
          element: $('<div id="MapInfoBox" class="InfoBox"></div>').get(0)
        }));
      }

      var infobox = this.infobox;
      var infobox_element = $(infobox.getElement());
      var coordinate = event.coordinate;
      var flights = this.flights;

      if (flights) {
        var flight_path_source = flights.get('source');
        var closest_feature = flight_path_source.getClosestFeatureToCoordinate(coordinate);

        if (closest_feature !== null) {
          var geometry = closest_feature.getGeometry();
          var closest_point = geometry.getClosestPoint(coordinate);
          var feature_pixel = event.map.getPixelFromCoordinate(closest_point);
          var mouse_pixel = event.map.getPixelFromCoordinate(coordinate);
          var squared_distance = Math.pow(mouse_pixel[0] - feature_pixel[0], 2) + Math.pow(mouse_pixel[1] - feature_pixel[1], 2);

          if (squared_distance < 100) {
            var time = closest_point[3];
            var sfid = closest_feature.get('sfid');
            var flight = flights.findBy('id', sfid); // flight info

            var flight_info = this.flightInfo(flight);
            infobox_element.append(flight_info); // near flights link

            var _loc = _openlayers.default.proj.transform(closest_point, 'EPSG:3857', 'EPSG:4326');

            var get_near_flights = this.nearFlights(_loc[0], _loc[1], time, flight);
            infobox_element.append(get_near_flights);
            coordinate = closest_point;
          }
        }
      } // location info


      var loc = _openlayers.default.proj.transform(coordinate, 'EPSG:3857', 'EPSG:4326');

      var get_location_info = this.locationInfo(loc[0], loc[1]);
      infobox_element.append(get_location_info);
      event.map.addOverlay(infobox);
      infobox.setPosition(coordinate);
      this.showCircle(coordinate);
      this.set('visible', true); // stop bubbeling

      return false;
    },

    /**
     * Returns the flight badge element
     * @param {slFlight} flight Flight object
     * @return {jQuery}
     */
    flightInfo: function flightInfo(flight) {
      return $("<span class=\"info-item badge\" style=\"background:".concat(flight.get('color'), "\">\n      ").concat(flight.getWithDefault('registration', ''), "\n    </span>"));
    },
    nearFlights: function nearFlights(lon, lat, time, flight) {
      var _this2 = this;

      var get_near_flights = $("<div class=\"info-item\">\n      <a class=\"near\" href=\"#NearFlights\">Load nearby flights</a>\n    </div>");
      get_near_flights.on('click touchend', function (e) {
        _this2.map.removeOverlay(_this2.infobox);

        _this2.getNearFlights(lon, lat, time, flight);

        _this2.setProperties({
          visible: false,
          infobox: null
        });

        e.preventDefault();
      });
      return get_near_flights;
    },
    locationInfo: function locationInfo(lon, lat) {
      var _this3 = this;

      var get_location_info = $("<div class=\"info-item\">\n      <a class=\"near\" href=\"#LocationInfo\">Get location info</a>\n    </div>");
      get_location_info.on('click touchend', function (event) {
        _this3.getLocationInfo(lon, lat);

        event.preventDefault();
      });
      return get_location_info;
    },

    /**
     * Show a circle at the clicked position
     *
     * @param {Array<Number>} coordinate Coordinate
     */
    showCircle: function showCircle(coordinate) {
      var stroke_style = new _openlayers.default.style.Stroke({
        color: '#f4bd33',
        width: 3
      });
      var circle_style = new _openlayers.default.style.Style({
        stroke: stroke_style
      });
      var circle = this.circle;

      if (!circle.geometry) {
        circle.geometry = new _openlayers.default.geom.Circle(coordinate, 1000);
      } else {
        circle.geometry.setCenterAndRadius(coordinate, 1000);
      }

      circle.animation = null;
      var map = this.map;
      map.on('postcompose', function (e) {
        var vector_context = e.vectorContext;

        if (circle.geometry) {
          if (circle.animation !== null) {
            var frame_state = e.frameState;

            if (!circle.animation.start) {
              circle.animation.start = frame_state.time;
            }

            if (circle.animation.duration <= 0 || frame_state.time > circle.animation.start + circle.animation.duration) {
              circle.geometry = null;
              return;
            }

            var delta_time = -(circle.animation.start - frame_state.time) % circle.animation.duration;
            stroke_style.setWidth(3 - delta_time / (circle.animation.duration / 3));
          }

          vector_context.setStyle(circle_style);
          vector_context.drawCircle(circle.geometry);
          map.render();
        }
      });
    },

    /**
     * Hides the search circle
     *
     * @param {Number} duration Fade duration in ms
     */
    hideCircle: function hideCircle(duration) {
      this.circle.animation = {
        duration: duration,
        start: null
      };
    },

    /**
     * Request near flights via ajax
     *
     * @param {Number} lon Longitude.
     * @param {Number} lat Latitude.
     * @param {Number} time Time.
     * @param {slFlight} flight Flight.
     */
    getNearFlights: function getNearFlights(lon, lat, time, flight) {
      var _this4 = this;

      var flights = this.flights;
      var addFlight = this.addFlight;

      if (!flights || !addFlight) {
        return;
      }

      var req = $.ajax("/api/flights/".concat(flight.get('id'), "/near?lon=").concat(lon, "&lat=").concat(lat, "&time=").concat(time));
      req.done(function (data) {
        for (var i = 0; i < data['flights'].length; ++i) {
          var _flight = data['flights'][i]; // skip retrieved flight if already on map

          if (flights.findBy('id', _flight['sfid'])) {
            continue;
          }

          addFlight(_flight);
        }
      });
      req.always(function () {
        return _this4.hideCircle(1000);
      });
    },

    /**
     * Request location informations via ajax
     *
     * @param {Number} lon Longitude.
     * @param {Number} lat Latitude.
     */
    getLocationInfo: function getLocationInfo(lon, lat) {
      var _this5 = this;

      var req = $.ajax("/api/mapitems?lon=".concat(lon, "&lat=").concat(lat));
      req.done(function (data) {
        return _this5.showLocationData(data);
      });
      req.fail(function () {
        return _this5.showLocationData(null);
      });
    },

    /**
     * Show location data in infobox
     *
     * @param {Object} data Location data.
     */
    showLocationData: function showLocationData(data) {
      var _this6 = this;

      // do nothing if infobox is closed already
      if (!this.visible) {
        return;
      }

      var infobox = this.infobox;
      var map = this.map;
      var element = $(infobox.getElement());
      element.empty();
      var item = $('<div class="location info-item"></div>');
      var no_data = true;

      if (data) {
        var airspace_layer = map.getLayers().getArray().filter(function (layer) {
          return layer.get('name') === 'Airspace';
        })[0];
        var mwp_layer = map.getLayers().getArray().filter(function (layer) {
          return layer.get('name') === 'Mountain Wave Project';
        })[0];

        if (!$.isEmptyObject(data['airspaces']) && airspace_layer.getVisible()) {
          var p = $('<p></p>');
          p.append(formatAirspaceData(data['airspaces']));
          item.append(p);
          no_data = false;
        }

        if (!$.isEmptyObject(data['waves']) && mwp_layer.getVisible()) {
          var _p = $('<p></p>');

          _p.append(formatMountainWaveData(data['waves']));

          item.append(_p);
          no_data = false;
        }
      }

      if (no_data) {
        item.html('No data retrieved for this location');
        element.delay(1500).fadeOut(1000, function () {
          map.removeOverlay(infobox);

          _this6.set('visible', false);
        });
        this.hideCircle(1000);
      }

      element.append(item);
    }
  });

  function slMapClickHandler(map, flights, addFlight) {
    return MapClickHandler.create({
      map: map,
      flights: flights,
      addFlight: addFlight
    });
  }
  /**
   * Format Airspace data for infobox
   *
   * @param {Object} data Airspace data.
   * @return {jQuery} HTML table with the airspace data.
   */


  function formatAirspaceData(data) {
    var table = $('<table></table>');
    table.append($("<thead>\n      <tr>\n        <th colspan=\"4\">Airspaces</th>\n      </tr>\n      <tr>\n        <th>Name</th>\n        <th>Class</th>\n        <th>Base</th>\n        <th>Top</th>\n      </tr>\n    </thead>"));
    var table_body = $('<tbody></tbody>');

    for (var i = 0; i < data.length; ++i) {
      table_body.append($("<tr>\n        <td class=\"airspace_name\">".concat(data[i]['name'], "</td>\n        <td class=\"airspace_class\">").concat(data[i]['class'], "</td>\n        <td class=\"airspace_base\">").concat(data[i]['base'], "</td>\n        <td class=\"airspace_top\">").concat(data[i]['top'], "</td>\n      </tr>")));
    }

    table.append(table_body);
    return table;
  }
  /**
   * Format Mountain Wave data in infobox
   *
   * @param {Object} data Wave data.
   * @return {jQuery} HTML table with the wave data.
   */


  function formatMountainWaveData(data) {
    var table = $('<table></table>');
    table.append($("<thead>\n      <tr>\n        <th colspan=\"2\">Mountain Waves</th>\n      </tr>\n      <tr>\n        <th>Name</th>\n        <th>Wind direction</th>\n      </tr>\n    </thead>"));
    var table_body = $('<tbody></tbody>');

    for (var i = 0; i < data.length; ++i) {
      var wind_direction = data[i]['main_wind_direction'] || 'Unknown';
      table_body.append($("<tr>\n        <td class=\"wave_name\">".concat(data[i]['name'], "</td>\n        <td class=\"wave_direction\">").concat(wind_direction, "</td>\n      </tr>")));
    }

    table.append(table_body);
    return table;
  }
});