define("skylines/components/takeoffs-layer", ["exports", "openlayers"], function (_exports, _openlayers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    map: null,
    locations: null,
    layer: Ember.computed(function () {
      return new _openlayers.default.layer.Vector({
        source: new _openlayers.default.source.Vector(),
        style: new _openlayers.default.style.Style({
          image: new _openlayers.default.style.Icon({
            anchor: [0.5, 1],
            src: '/images/marker-gold.png'
          })
        }),
        name: 'Takeoff Locations',
        id: 'TakeoffLocations',
        zIndex: 51
      });
    }),
    source: Ember.computed('layer', function () {
      return this.layer.getSource();
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.map.addLayer(this.layer);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.map.removeLayer(this.layer);
    }
  });

  _exports.default = _default;
});