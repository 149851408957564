define("skylines/templates/components/aircraft-model-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BnlKb4u5",
    "block": "{\"symbols\":[\"model\"],\"statements\":[[5,\"power-select\",[],[[\"@options\",\"@selected\",\"@searchField\",\"@onchange\"],[[22,\"modelsWithNull\"],[22,\"model\"],\"name\",[28,\"action\",[[23,0,[]],\"onChange\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"id\"]],null],null]],null,{\"statements\":[[0,\"    [\"],[1,[28,\"t\",[\"unspecified\"],null],false],[0,\"]\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/aircraft-model-select.hbs"
    }
  });

  _exports.default = _default;
});