define("skylines/templates/components/flight-performance-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uh7kM+L0",
    "block": "{\"symbols\":[\"@performance\"],\"statements\":[[7,\"h4\",true],[8],[1,[28,\"t\",[\"circling-performance\"],null],false],[9],[0,\"\\n\"],[5,\"circling-performance-table\",[],[[\"@perf\"],[[23,1,[\"circling\"]]]]],[0,\"\\n\\n\"],[7,\"h4\",true],[8],[1,[28,\"t\",[\"cruise-performance\"],null],false],[9],[0,\"\\n\"],[5,\"cruise-performance-table\",[],[[\"@perf\"],[[23,1,[\"cruise\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/flight-performance-panel.hbs"
    }
  });

  _exports.default = _default;
});