define("skylines/templates/components/flight-leg-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z+HFPJea",
    "block": "{\"symbols\":[\"@legs\",\"@selection\",\"@onSelect\"],\"statements\":[[7,\"h4\",true],[8],[1,[28,\"t\",[\"distance\"],null],false],[9],[0,\"\\n\"],[5,\"flight-leg-table\",[],[[\"@legs\",\"@selection\",\"@onSelect\"],[[23,1,[\"classic\"]],[23,2,[]],[23,3,[]]]]],[0,\"\\n\\n\"],[7,\"h4\",true],[8],[1,[28,\"t\",[\"triangle\"],null],false],[9],[0,\"\\n\"],[5,\"flight-leg-table\",[],[[\"@legs\",\"@selection\",\"@onSelect\"],[[23,1,[\"triangle\"]],[23,2,[]],[23,3,[]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/flight-leg-panel.hbs"
    }
  });

  _exports.default = _default;
});