define("skylines/routes/flight/change-aircraft", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model: function model() {
      var id = this.modelFor('flight').ids[0];
      var flight = this.ajax.request("/api/flights/".concat(id, "/")).then(function (it) {
        return it.flight;
      });
      var aircraftModels = this.ajax.request('/api/aircraft-models').then(function (it) {
        return it.models;
      });
      return Ember.RSVP.hash({
        id: id,
        flight: flight,
        aircraftModels: aircraftModels
      });
    }
  });

  _exports.default = _default;
});