define("skylines/templates/components/login-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "56QN0d3k",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\",false],[12,\"class\",\"form-login\"],[3,\"action\",[[23,0,[]],[28,\"perform\",[[24,[\"loginTask\"]]],null]],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"error\"]],[28,\"not\",[[24,[\"inline\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[5,\"bs-alert\",[],[[\"@type\",\"@dismissible\"],[\"danger\",false]],{\"statements\":[[1,[28,\"t\",[\"wrong-credentials\"],null],false]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[5,\"validated-input\",[],[[\"@value\",\"@label\",\"@autocomplete\",\"@disabled\",\"@forceErrorClass\",\"@didValidate\",\"@testSelector\"],[[22,\"email\"],[28,\"t\",[\"email-address\"],null],\"email\",[24,[\"loginTask\",\"isRunning\"]],[22,\"error\"],[22,\"didValidate\"],\"login-email\"]]],[0,\"\\n\\n  \"],[5,\"validated-input\",[],[[\"@type\",\"@value\",\"@label\",\"@autocomplete\",\"@disabled\",\"@forceErrorClass\",\"@didValidate\",\"@testSelector\"],[\"password\",[22,\"password\"],[28,\"t\",[\"password\"],null],\"current-password\",[24,[\"loginTask\",\"isRunning\"]],[22,\"error\"],[22,\"didValidate\"],\"login-password\"]]],[0,\"\\n\\n  \"],[7,\"button\",true],[10,\"class\",\"btn btn-primary btn-block\"],[11,\"disabled\",[24,[\"loginTask\",\"isRunning\"]]],[10,\"type\",\"submit\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"login\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"hr\",true],[8],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[8],[5,\"link-to\",[],[[\"@route\"],[\"users.recover\"]],{\"statements\":[[1,[28,\"t\",[\"forgot-password\"],null],false]],\"parameters\":[]}],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[5,\"link-to\",[],[[\"@route\"],[\"users.new\"]],{\"statements\":[[1,[28,\"t\",[\"dont-have-an-account\"],null],false]],\"parameters\":[]}],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/login-form.hbs"
    }
  });

  _exports.default = _default;
});