define("skylines/services/raven", ["exports", "ember-ajax/errors", "ember-cli-sentry/services/raven"], function (_exports, _errors, _raven) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _raven.default.extend({
    ignoreError: function ignoreError(error) {
      return (0, _errors.isAbortError)(error);
    }
  });

  _exports.default = _default;
});