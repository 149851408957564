define("skylines/components/timeline-events/follower", ["exports", "skylines/computed/safe-computed", "skylines/components/timeline-events/-base"], function (_exports, _safeComputed, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    accountUserIsFollowed: (0, _safeComputed.default)('account.user', 'event.user', function (accountUser, user) {
      return accountUser.id === user.id;
    }),
    translationKey: Ember.computed('accountUserIsActor', 'accountUserIsFollowed', function () {
      var i = 1;

      if (this.accountUserIsActor) {
        i += 1;
      }

      if (this.accountUserIsFollowed) {
        i += 2;
      }

      return "timeline-events.follower.message".concat(i);
    })
  });

  _exports.default = _default;
});