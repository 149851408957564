define("skylines/templates/groupflights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bSHrmj8j",
    "block": "{\"symbols\":[],\"statements\":[[5,\"base-page\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"page-header\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"groupflights\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-xs-12 banner\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-xs-6\"],[8],[5,\"link-to\",[[12,\"class\",\"btn btn-default\"]],[[\"@route\"],[\"settings.club\"]],{\"statements\":[[0,\" \"],[1,[28,\"t\",[\"group-choose-new\"],null],false]],\"parameters\":[]}],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-xs-6\"],[8],[5,\"link-to\",[[12,\"class\",\"btn btn-default\"]],[[\"@route\"],[\"groupflights.info\"]],{\"statements\":[[7,\"i\",true],[10,\"class\",\"fa fa-question-circle\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" \"],[1,[28,\"t\",[\"groupflights-info\"],null],false]],\"parameters\":[]}],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"loading\"]]],null,{\"statements\":[[0,\"        \"],[7,\"small\",true],[8],[1,[28,\"t\",[\"loading\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"p\",true],[8],[1,\"   \",false],[9],[0,\"\\n      \"],[7,\"hr\",true],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n      \"],[1,[22,\"outlet\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/groupflights.hbs"
    }
  });

  _exports.default = _default;
});