define("skylines/services/ajax", ["exports", "ember-ajax/services/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      var headers = {};
      var authToken = this.get('session.data.authenticated.access_token');

      if (authToken) {
        headers['Authorization'] = "Bearer ".concat(authToken);
      }

      return headers;
    }),
    options: function options(url) {
      var _options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (_options.json) {
        _options.contentType = 'application/json';
        _options.data = JSON.stringify(_options.json);
        delete _options.json;
      }

      return this._super(url, _options);
    }
  });

  _exports.default = _default;
});