define("skylines/templates/tracking/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2xXbLxNf",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"pilots\",\"length\"]],1],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"page-title\",[[28,\"t\",[\"live-tracking-of-PILOT\"],[[\"pilot\"],[[24,[\"model\",\"pilots\",\"0\",\"name\"]]]]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"pilots\",\"length\"]],2],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"page-title\",[[28,\"t\",[\"live-tracking-of-PILOT-and-PILOT2\"],[[\"pilot\",\"pilot2\"],[[24,[\"model\",\"pilots\",\"0\",\"name\"]],[24,[\"model\",\"pilots\",\"1\",\"name\"]]]]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"page-title\",[[28,\"t\",[\"live-tracking-of-PILOT-and-NUM-other-pilots\"],[[\"pilot\",\"num\"],[[24,[\"model\",\"pilots\",\"0\",\"name\"]],[28,\"sum\",[[24,[\"model\",\"pilots\",\"length\"]],-1],null]]]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"fullscreen-below-nav-bar\"],[8],[0,\"\\n  \"],[5,\"tracking-page\",[],[[\"@id\",\"@pilots\",\"@flights\"],[\"fullscreen-content\",[24,[\"model\",\"pilots\"]],[24,[\"model\",\"flights\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/tracking/details.hbs"
    }
  });

  _exports.default = _default;
});