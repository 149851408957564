define("skylines/components/plane-icons-composer", ["exports", "openlayers"], function (_exports, _openlayers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    map: null,
    fixes: null,
    fixesObserver: Ember.observer('fixes.@each.pointXY', function () {
      Ember.run.once(this.map, 'render');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('icons', {});
      this.set('styles', {});

      this._initStyle('glider', {
        size: [40, 24],
        src: '/images/glider_symbol.png'
      });

      this._initStyle('motorglider', {
        size: [40, 24],
        src: '/images/motorglider_symbol.png'
      });

      this._initStyle('paraglider', {
        size: [40, 24],
        src: '/images/paraglider_symbol.png'
      });

      this._initStyle('hangglider', {
        size: [40, 14],
        src: '/images/hangglider_symbol.png'
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.map.on('postcompose', this.onPostCompose, this);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.map.un('postcompose', this.onPostCompose, this);
    },
    _initStyle: function _initStyle(key, _ref) {
      var src = _ref.src,
          size = _ref.size;
      var icon = new _openlayers.default.style.Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        size: size,
        src: src,
        rotation: 0,
        rotateWithView: true
      });
      icon.load();
      var style = new _openlayers.default.style.Style({
        image: icon
      });
      this.set("icons.".concat(key), icon);
      this.set("styles.".concat(key), style);
    },
    onPostCompose: function onPostCompose(e) {
      this.renderIcons(e.vectorContext);
    },
    renderIcons: function renderIcons(context) {
      var icons = this.icons;
      var styles = this.styles;
      this.fixes.forEach(function (fix) {
        var point = fix.get('pointXY');

        if (point) {
          var type = Ember.getWithDefault(fix, 'flight.model.type', 'glider');
          var icon = icons[type] || icons['glider'];
          var style = styles[type] || styles['glider'];
          icon.setRotation(fix.get('heading'));
          context.setStyle(style);
          context.drawGeometry(point);
        }
      });
    }
  });

  _exports.default = _default;
});