define("skylines/instance-initializers/head-browser", ["exports", "skylines/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = _initialize;
  _exports.default = void 0;

  function _initialize() {
    if (_environment.default['ember-cli-head'] && _environment.default['ember-cli-head']['suppressBrowserRender']) {
      return true;
    } // clear fast booted head (if any)


    var startMeta = document.querySelector('meta[name="ember-cli-head-start"]');
    var endMeta = document.querySelector('meta[name="ember-cli-head-end"]');

    if (startMeta && endMeta) {
      var el = startMeta.nextSibling;

      while (el && el !== endMeta) {
        document.head.removeChild(el);
        el = startMeta.nextSibling;
      }

      document.head.removeChild(startMeta);
      document.head.removeChild(endMeta);
    }
  }

  var _default = {
    name: 'head-browser',
    initialize: function initialize() {
      if (typeof FastBoot === 'undefined') {
        _initialize.apply(void 0, arguments);
      }
    }
  };
  _exports.default = _default;
});