define("skylines/components/flight-path-layer", ["exports", "openlayers"], function (_exports, _openlayers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_COLOR = '#004bbd';

  var _default = Ember.Component.extend({
    tagName: '',
    map: null,
    flights: null,
    layer: Ember.computed(function () {
      return new _openlayers.default.layer.Vector({
        source: new _openlayers.default.source.Vector(),
        style: style_function,
        name: 'Flight',
        zIndex: 50
      });
    }),
    source: Ember.computed('layer', function () {
      return this.layer.getSource();
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.map.addLayer(this.layer);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.map.removeLayer(this.layer);
    }
  });
  /**
   * Determin the drawing style for the feature
   * @param {ol.feature} feature Feature to style
   * @return {!Array<ol.style.Style>} Style of the feature
   */


  _exports.default = _default;

  function style_function(feature) {
    var color = DEFAULT_COLOR;

    if (feature.getKeys().includes('color')) {
      color = feature.get('color');
    }

    return [new _openlayers.default.style.Style({
      stroke: new _openlayers.default.style.Stroke({
        color: color,
        width: 2
      }),
      zIndex: 1000
    })];
  }
});