define("skylines/templates/components/pilot-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BKbqMFBg",
    "block": "{\"symbols\":[\"pilot\"],\"statements\":[[5,\"power-select\",[],[[\"@options\",\"@selected\",\"@onchange\",\"@searchField\"],[[22,\"pilots\"],[22,\"pilot\"],[28,\"action\",[[23,0,[]],\"onChange\"],null],\"name\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"id\"]],null],null]],null,{\"statements\":[[0,\"    [\"],[1,[28,\"t\",[\"unknown-or-other-person\"],null],false],[0,\"]\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/pilot-select.hbs"
    }
  });

  _exports.default = _default;
});