define("skylines/templates/components/takeoffs-layer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ylpycx8L",
    "block": "{\"symbols\":[\"location\"],\"statements\":[[4,\"each\",[[24,[\"locations\"]]],null,{\"statements\":[[0,\"  \"],[5,\"takeoffs-layer-feature\",[],[[\"@source\",\"@location\"],[[22,\"source\"],[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/takeoffs-layer.hbs"
    }
  });

  _exports.default = _default;
});