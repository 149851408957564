define("skylines/utils/flight-collection", ["exports", "openlayers"], function (_exports, _openlayers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.ArrayProxy.extend({
    init: function init() {
      this.set('content', []);
      this.set('source', new _openlayers.default.source.Vector());

      this._super.apply(this, arguments);
    },

    /**
     * Calculates the bounds of all flights in the collection.
     * @return {ol.extent}
     */
    getBounds: function getBounds() {
      return this.source.getExtent();
    },

    /**
     * Returns the minimum and maximum fix time within the extent.
     * Code based on ol.render.canvas.Replay.prototype.appendFlatCoordinates.
     * @param {ol.extent} extent
     * @return {Object}
     */
    getMinMaxTimeInExtent: function getMinMaxTimeInExtent(extent) {
      var min = Infinity;
      var max = -Infinity;
      var total_min = Infinity;
      var total_max = -Infinity;
      this.source.forEachFeatureInExtent(extent, function (feature) {
        var coordinates = feature.getGeometry().getCoordinates();
        var lastCoord = coordinates[0];
        var nextCoord = null;
        var end = coordinates.length;

        var lastRel = _openlayers.default.extent.containsCoordinate(extent, lastCoord);

        total_min = Math.min(total_min, lastCoord[3]);

        if (lastRel === true) {
          min = Math.min(lastCoord[3], min);
        }

        for (var i = 1; i < end; i += 1) {
          nextCoord = coordinates[i];

          var nextRel = _openlayers.default.extent.containsCoordinate(extent, nextCoord); // current vector completely within extent. do nothing.
          // current vector completely outside extent. do nothing.
          // last vertice was inside extent, next one is outside.


          if (lastRel && !nextRel) {
            max = Math.max(nextCoord[3], max);
            lastRel = nextRel;
          } else if (!lastRel && nextRel) {
            // last vertice was outside extent, next one is inside
            min = Math.min(lastCoord[3], min);
          }

          lastCoord = nextCoord;
          lastRel = nextRel;
        }

        if (lastRel === true) {
          max = Math.max(lastCoord[3], max);
        }

        total_max = Math.max(total_max, lastCoord[3]);
      });

      if (min === Infinity) {
        min = total_min;
      }

      if (max === -Infinity) {
        max = total_max;
      }

      return {
        min: min,
        max: max
      };
    },
    arrayContentWillChange: function arrayContentWillChange(offset, removeCount) {
      var _this = this;

      this._super.apply(this, arguments);

      var flights = this.content;
      var removedFlights = flights.slice(offset, offset + removeCount);
      var source = this.source;
      removedFlights.forEach(function (flight) {
        var id = flight.get('id');

        _this.source.getFeatures().filter(function (feature) {
          return feature.get('sfid') === id;
        }).forEach(function (feature) {
          return source.removeFeature(feature);
        });
      });
    },
    arrayContentDidChange: function arrayContentDidChange(offset, removeCount, addCount) {
      var flights = this.content;
      var addedFlights = flights.slice(offset, offset + addCount);
      var source = this.source;
      addedFlights.forEach(function (flight) {
        var feature = new _openlayers.default.Feature({
          geometry: flight.get('geometry'),
          sfid: flight.get('id'),
          color: flight.get('color'),
          type: 'flight'
        });
        source.addFeature(feature);
      });

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});