define("skylines/templates/flight-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8Q1PjZR9",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"result\"]]],null,{\"statements\":[[0,\"  \"],[5,\"base-page\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"page-header\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[8],[1,[28,\"t\",[\"upload-flight\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"upload-results-form\",[],[[\"@results\",\"@clubMembers\",\"@aircraftModels\",\"@transitionTo\"],[[24,[\"result\",\"results\"]],[24,[\"result\",\"club_members\"]],[24,[\"result\",\"aircraft_models\"]],[28,\"action\",[[23,0,[]],\"transitionTo\"],null]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"form-page\",[],[[\"@title\"],[[28,\"t\",[\"upload-flight\"],null]]],{\"statements\":[[0,\"\\n    \"],[5,\"upload-flight-form\",[],[[\"@clubMembers\",\"@onUpload\"],[[24,[\"model\",\"clubMembers\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"result\"]]],null]],null]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/flight-upload.hbs"
    }
  });

  _exports.default = _default;
});