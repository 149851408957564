define("skylines/components/pin-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pinnedFlights: Ember.inject.service(),
    tagName: 'span',
    classNames: ['pin'],
    classNameBindings: ['pinned'],
    pinned: Ember.computed('pinnedFlights.pinned.[]', 'flightId', function () {
      return this.pinnedFlights.pinned.includes(this.flightId);
    }),
    click: function click() {
      this.pinnedFlights.toggle(this.flightId);
    }
  });

  _exports.default = _default;
});