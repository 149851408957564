define("skylines/components/layer-switcher-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    highlighted: false,
    imagePath: Ember.computed('layer.visible', 'highlighted', function () {
      var colorful = this.get('layer.visible') || this.highlighted;
      return "../../images/layers/".concat(this.get('layer.name')).concat(colorful ? '.png' : '.bw.png');
    }),
    mouseEnter: function mouseEnter() {
      this.set('highlighted', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('highlighted', false);
    },
    touchStart: function touchStart() {
      this.set('highlighted', true);
    },
    touchEnd: function touchEnd() {
      this.set('highlighted', false);
    },
    click: function click() {
      this.onSelect(this.layer);
    }
  });

  _exports.default = _default;
});