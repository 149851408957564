define("skylines/utils/upload-result", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Validations = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    pilotName: {
      descriptionKey: 'pilot',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })],
      debounce: 500
    },
    copilotId: {
      descriptionKey: 'copilot',
      validators: [(0, _emberCpValidations.validator)('not-equal', {
        on: 'pilotId',
        messageKey: 'pilots-must-not-be-equal'
      })],
      debounce: 0
    },
    copilotName: {
      descriptionKey: 'copilot',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })],
      debounce: 500
    },
    registration: {
      descriptionKey: 'registration',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 32
      })],
      debounce: 500
    },
    competitionId: {
      descriptionKey: 'competition-id',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 5
      })],
      debounce: 500
    }
  });
  _exports.Validations = Validations;

  var _default = Ember.Object.extend(Validations, {
    pilotId: Ember.computed.alias('flight.pilotId'),
    pilotName: Ember.computed.alias('flight.pilotName'),
    copilotId: Ember.computed.alias('flight.copilotId'),
    copilotName: Ember.computed.alias('flight.copilotName'),
    modelId: Ember.computed.alias('flight.modelId'),
    registration: Ember.computed.alias('flight.registration'),
    competitionId: Ember.computed.alias('flight.competitionId'),
    success: Ember.computed.equal('status', 0)
  });

  _exports.default = _default;
});