define("skylines/components/takeoffs-map", ["exports", "skylines/utils/map-click-handler", "skylines/components/base-map"], function (_exports, _mapClickHandler, _baseMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseMap.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.fit();
      (0, _mapClickHandler.default)(this.map);
    },
    fit: function fit() {
      var map = this.map;
      var layer = map.getLayers().getArray().find(function (layer) {
        return layer.get('id') === 'TakeoffLocations';
      });

      if (layer) {
        var source = layer.getSource();
        var view = map.getView();
        view.fit(source.getExtent());
        var zoom = view.getZoom();

        if (zoom > 10) {
          view.setZoom(10);
        }
      }
    }
  });

  _exports.default = _default;
});