define("skylines/templates/flight/change-pilot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "846pjiOF",
    "block": "{\"symbols\":[],\"statements\":[[5,\"form-page\",[],[[\"@title\"],[[28,\"t\",[\"choose-pilot\"],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"change-pilot-form\",[],[[\"@flightId\",\"@flight\",\"@clubMembers\",\"@onDidSave\"],[[24,[\"model\",\"id\"]],[24,[\"model\",\"flight\"]],[24,[\"model\",\"clubMembers\"]],[28,\"action\",[[23,0,[]],\"transitionToFlight\"],null]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/flight/change-pilot.hbs"
    }
  });

  _exports.default = _default;
});