define("skylines/templates/components/flight-leg-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VIFep8RA",
    "block": "{\"symbols\":[\"leg\",\"index\",\"@selection\",\"@onSelect\",\"@legs\"],\"statements\":[[4,\"if\",[[23,5,[]]],null,{\"statements\":[[0,\"  \"],[7,\"table\",true],[10,\"class\",\"table table-condensed text-right\"],[8],[0,\"\\n    \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[10,\"class\",\"small\"],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[9],[0,\"\\n      \"],[7,\"th\",true],[10,\"class\",\"numeric\"],[8],[1,[28,\"t\",[\"distance-abbr\"],null],false],[9],[0,\"\\n      \"],[7,\"th\",true],[10,\"class\",\"numeric hidden-md\"],[8],[7,\"abbr\",true],[11,\"title\",[28,\"t\",[\"duration\"],null]],[8],[0,\"Δt\"],[9],[9],[0,\"\\n      \"],[7,\"th\",true],[10,\"class\",\"numeric\"],[8],[1,[28,\"t\",[\"avg-speed-abbr\"],null],false],[9],[0,\"\\n      \"],[7,\"th\",true],[10,\"class\",\"numeric\"],[8],[1,[28,\"t\",[\"climb-percentage-abbr\"],null],false],[9],[0,\"\\n      \"],[7,\"th\",true],[10,\"class\",\"numeric\"],[8],[1,[28,\"t\",[\"avg-vario-abbr\"],null],false],[9],[0,\"\\n      \"],[7,\"th\",true],[10,\"class\",\"numeric\"],[8],[7,\"abbr\",true],[11,\"title\",[28,\"t\",[\"glide-rate\"],null]],[8],[1,[28,\"t\",[\"glide-rate-abbr\"],null],false],[9],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,5,[]]],null,{\"statements\":[[0,\"      \"],[5,\"flight-leg-row\",[],[[\"@num\",\"@leg\",\"@selection\",\"@onSelect\"],[[28,\"sum\",[[23,2,[]],1],null],[23,1,[]],[23,3,[]],[23,4,[]]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"t\",[\"no-data\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/flight-leg-table.hbs"
    }
  });

  _exports.default = _default;
});