define("skylines/components/tracking-page", ["exports", "jquery", "openlayers", "skylines/utils/fix-calc"], function (_exports, _jquery, _openlayers, _fixCalc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    units: Ember.inject.service(),
    classNames: ['relative-fullscreen'],
    fixCalc: null,
    timeInterval: Ember.computed('mapExtent', 'cesiumEnabled', 'fixCalc.flights.[]', function () {
      if (this.cesiumEnabled) {
        return null;
      }

      var extent = this.mapExtent;

      if (!extent) {
        return null;
      }

      var interval = this.get('fixCalc.flights').getMinMaxTimeInExtent(extent);
      return interval.max === -Infinity ? null : [interval.min, interval.max];
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var ajax = this.ajax;
      var units = this.units;

      var fixCalc = _fixCalc.default.create({
        ajax: ajax,
        units: units
      });

      this.set('fixCalc', fixCalc);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var flights = this.flights;

      if (flights.length === 0) {
        return;
      }

      var fixCalc = this.fixCalc;
      var sidebar = this.element.querySelector('#sidebar');
      var $sidebar = (0, _jquery.default)(sidebar).sidebar();
      var barogramPanel = this.element.querySelector('#barogram_panel');
      var $barogramPanel = (0, _jquery.default)(barogramPanel);
      var olScaleLine = this.element.querySelector('.ol-scale-line');
      var olAttribution = this.element.querySelector('.ol-attribution');

      var resize = function resize() {
        var bottom = Number(getComputedStyle(barogramPanel).bottom.replace('px', ''));
        var height = barogramPanel.offsetHeight + bottom;
        sidebar.style.bottom = "".concat(height, "px");
        olScaleLine.style.bottom = "".concat(height, "px");
        olAttribution.style.bottom = "".concat(height, "px");
      };

      resize();
      $barogramPanel.resize(resize);

      if (window.location.hash && sidebar.querySelector("li > a[href=\"#".concat(window.location.hash.substring(1), "\"]"))) {
        $sidebar.open(window.location.hash.substring(1));
      } else if (window.innerWidth >= 768 && flights.length > 1) {
        $sidebar.open('tab-overview');
      }

      var map = window.flightMap.get('map');
      fixCalc.set('defaultTime', -1);
      fixCalc.set('time', -1);
      flights.forEach(function (flight) {
        return fixCalc.addFlight(flight);
      });
      var extent = fixCalc.get('flights').getBounds();
      map.getView().fit(extent, {
        padding: this._calculatePadding()
      }); // update flight track every 15 seconds

      this._scheduleUpdate();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var updateTimer = this.updateTimer;

      if (updateTimer) {
        Ember.run.cancel(updateTimer);
      }
    },
    actions: {
      togglePlayback: function togglePlayback() {
        this.fixCalc.togglePlayback();
      },
      removeFlight: function removeFlight(id) {
        var flights = this.get('fixCalc.flights');
        flights.removeObjects(flights.filterBy('id', id));
      },
      calculatePadding: function calculatePadding() {
        return this._calculatePadding();
      }
    },
    _scheduleUpdate: function _scheduleUpdate() {
      var _this = this;

      this.set('updateTimer', Ember.run.later(function () {
        return _this._update();
      }, 15 * 1000));
    },
    _update: function _update() {
      var flights = this.get('fixCalc.flights');
      var ajax = this.ajax;
      flights.forEach(function (flight) {
        var last_update = flight.get('last_update') || null;
        var data = {
          last_update: last_update
        };
        ajax.request("/api/live/".concat(flight.get('id'), "/json"), {
          data: data
        }).then(function (data) {
          updateFlight(flights, data);
        }).catch(function () {// ignore update errors
        });
      });

      this._scheduleUpdate();
    },
    _calculatePadding: function _calculatePadding() {
      var sidebar = this.element.querySelector('#sidebar');
      var barogramPanel = this.element.querySelector('#barogram_panel');
      return [20, 20, barogramPanel.offsetHeight + 20, sidebar.offsetWidth + 20];
    }
  });
  /**
   * Updates a tracking flight.
   *
   * @param {Object} data The data returned by the JSON request.
   */


  _exports.default = _default;

  function updateFlight(flights, data) {
    // find the flight to update
    var flight = flights.findBy('id', data.sfid);

    if (!flight) {
      return;
    }

    var time_decoded = _openlayers.default.format.Polyline.decodeDeltas(data.barogram_t, 1, 1);

    var lonlat = _openlayers.default.format.Polyline.decodeDeltas(data.points, 2);

    var height_decoded = _openlayers.default.format.Polyline.decodeDeltas(data.barogram_h, 1, 1);

    var enl_decoded = _openlayers.default.format.Polyline.decodeDeltas(data.enl, 1, 1);

    var elev = _openlayers.default.format.Polyline.decodeDeltas(data.elevations, 1, 1); // we skip the first point in the list because we assume it's the "linking"
    // fix between the data we already have and the data to add.


    if (time_decoded.length < 2) {
      return;
    }

    var geoid = flight.get('geoid');
    var fixes = time_decoded.map(function (timestamp, i) {
      return {
        time: timestamp,
        longitude: lonlat[i * 2],
        latitude: lonlat[i * 2 + 1],
        altitude: height_decoded[i] + geoid,
        enl: enl_decoded[i]
      };
    });
    var elevations = time_decoded.map(function (timestamp, i) {
      return {
        time: timestamp,
        elevation: elev[i] > -500 ? elev[i] : null
      };
    });
    flight.get('fixes').pushObjects(fixes.slice(1));
    flight.get('elevations').pushObjects(elevations.slice(1));
  }
});