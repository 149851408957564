define("skylines/components/datetime-picker", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['input-group', 'input-group-sm', 'date'],
    date: null,
    minDate: false,
    maxDate: false,
    onChange: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var $element = (0, _jquery.default)(this.element);
      $element.datetimepicker({
        pickDate: false,
        useSeconds: true,
        format: 'HH:mm:ss',
        icons: {
          time: 'fa fa-clock-o',
          date: 'fa fa-calendar',
          up: 'fa fa-chevron-up',
          down: 'fa fa-chevron-down'
        },
        minDate: this.minDate,
        maxDate: this.maxDate
      });
      $element.on('dp.change', function (_ref) {
        var date = _ref.date;

        _this.onChange(date.toDate());
      });
      this.set('picker', $element.data('DateTimePicker'));
      Ember.run.once(this, 'updateDate');
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      Ember.run.once(this, 'updateDate');
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)(this.element).off('dp.change');
      this.set('picker', null);
    },
    updateDate: function updateDate() {
      var picker = this.picker;

      if (picker) {
        picker.setValue(this.date);
      }
    }
  });

  _exports.default = _default;
});