define("skylines/templates/components/user-distance-flight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JOUHQUKA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"column-buttons\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"flight\"]]],null,{\"statements\":[[0,\"    \"],[7,\"abbr\",true],[11,\"title\",[28,\"format-date\",[[24,[\"flight\",\"flightDate\"]]],[[\"format\",\"timeZone\"],[\"ddmmyyyy\",\"UTC\"]]]],[8],[1,[28,\"format-relative\",[[24,[\"flight\",\"flightDate\"]]],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"pull-right\"],[8],[0,\"\\n      \"],[5,\"link-to\",[[12,\"class\",\"btn btn-default btn-sm\"]],[[\"@route\",\"@model\"],[\"flight\",[24,[\"flight\",\"id\"]]]],{\"statements\":[[1,[28,\"t\",[\"show\"],null],false]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/user-distance-flight.hbs"
    }
  });

  _exports.default = _default;
});