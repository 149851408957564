define("skylines/services/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    sessionData: Ember.computed.alias('session.data.authenticated.settings'),
    user: Ember.computed('sessionData.{id,name}', function () {
      var sessionData = this.sessionData;

      if (sessionData) {
        return Ember.getProperties(sessionData, 'id', 'name');
      }
    }),
    club: Ember.computed.alias('sessionData.club')
  });

  _exports.default = _default;
});