define("skylines/templates/flight/change-aircraft", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MvTRxkyp",
    "block": "{\"symbols\":[],\"statements\":[[5,\"form-page\",[],[[\"@title\"],[[28,\"t\",[\"choose-aircraft\"],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"change-aircraft-form\",[],[[\"@flightId\",\"@modelId\",\"@registration\",\"@competitionId\",\"@models\",\"@onDidSave\"],[[24,[\"model\",\"id\"]],[28,\"readonly\",[[24,[\"model\",\"flight\",\"model\",\"id\"]]],null],[28,\"readonly\",[[24,[\"model\",\"flight\",\"registration\"]]],null],[28,\"readonly\",[[24,[\"model\",\"flight\",\"competitionId\"]]],null],[24,[\"model\",\"aircraftModels\"]],[28,\"action\",[[23,0,[]],\"transitionToFlight\"],null]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/flight/change-aircraft.hbs"
    }
  });

  _exports.default = _default;
});