define("skylines/templates/flights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EuaxDYse",
    "block": "{\"symbols\":[],\"statements\":[[5,\"base-page\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"page-header\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"flights\"],null],false],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"center-block\"],[10,\"style\",\"width:500px\"],[8],[0,\"\\n      \"],[5,\"link-to\",[[12,\"class\",\"btn btn-default\"]],[[\"@route\"],[\"flight-upload\"]],{\"statements\":[[0,\" \"],[1,[28,\"t\",[\"upload\"],null],false]],\"parameters\":[]}],[0,\"\\n      \"],[5,\"link-to\",[[12,\"class\",\"btn btn-default\"]],[[\"@route\"],[\"flights.info\"]],{\"statements\":[[0,\" \"],[7,\"i\",true],[10,\"class\",\"fa fa-question-circle\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" \"],[1,[28,\"t\",[\"scoring\"],null],false]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"loading\"]]],null,{\"statements\":[[0,\"        \"],[7,\"small\",true],[8],[1,[28,\"t\",[\"loading\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n      \"],[1,[22,\"outlet\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/flights.hbs"
    }
  });

  _exports.default = _default;
});