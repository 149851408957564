define("skylines/templates/components/flight-path-layer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1A9c/RN7",
    "block": "{\"symbols\":[\"flight\"],\"statements\":[[4,\"each\",[[24,[\"flights\"]]],null,{\"statements\":[[0,\"  \"],[5,\"flight-path-layer-feature\",[],[[\"@source\",\"@flight\"],[[22,\"source\"],[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/flight-path-layer.hbs"
    }
  });

  _exports.default = _default;
});