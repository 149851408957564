define("skylines/templates/settings/club", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G102ltfx",
    "block": "{\"symbols\":[],\"statements\":[[5,\"settings-page\",[],[[\"@title\"],[[28,\"t\",[\"change-club\"],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"settings-panels/choose-club\",[],[[\"@clubs\",\"@clubId\"],[[24,[\"model\",\"clubs\"]],[28,\"readonly\",[[24,[\"account\",\"club\",\"id\"]]],null]]]],[0,\"\\n  \"],[5,\"settings-panels/register-club\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/settings/club.hbs"
    }
  });

  _exports.default = _default;
});