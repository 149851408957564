define("skylines/components/flight-barogram", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "skylines/computed/safe-computed", "skylines/components/base-barogram"], function (_exports, _slicedToArray2, _safeComputed, _baseBarogram) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseBarogram.default.extend({
    flights: null,
    time: null,
    defaultTime: null,
    flightsObserver: Ember.observer('flights.[]', function () {
      this.draw();
    }),
    selection: null,
    activeFlights: Ember.computed('flights.[]', 'selection', function () {
      var flights = this.flights,
          selection = this.selection;
      return flights.filter(function (flight) {
        return !selection || flight.get('id') === selection;
      });
    }),
    passiveFlights: Ember.computed('flights.[]', 'selection', function () {
      var flights = this.flights,
          selection = this.selection;
      return flights.filter(function (flight) {
        return selection && flight.get('id') !== selection;
      });
    }),
    active: Ember.computed('activeFlights.@each.{flot_h,color}', function () {
      return this.activeFlights.map(function (flight) {
        return {
          data: flight.get('flot_h'),
          color: flight.get('color')
        };
      });
    }),
    passive: Ember.computed('passiveFlights.@each.{flot_h,color}', function () {
      return this.passiveFlights.map(function (flight) {
        return {
          data: flight.get('flot_h'),
          color: flight.get('color')
        };
      });
    }),
    enls: Ember.computed('activeFlights.@each.{flot_enl,color}', function () {
      return this.activeFlights.map(function (flight) {
        return {
          data: flight.get('flot_enl'),
          color: flight.get('color')
        };
      });
    }),
    selectedFlight: Ember.computed('flights.@each.id', 'selection', function () {
      if (this.flights.length === 1) {
        return this.flights[0];
      }

      if (this.selection) {
        return this.flights.findBy('id', this.selection);
      }
    }),
    contests: (0, _safeComputed.default)('selectedFlight', function (flight) {
      return flight.get('contests');
    }),
    elevations: (0, _safeComputed.default)('selectedFlight', function (flight) {
      return flight.get('flot_elev');
    }),
    timeInterval: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.onHoverModeUpdate();
      this.placeholder.on('plotclick', function (event, pos) {
        _this.set('time', pos.x / 1000);
      });
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var selection = this.selection;
      var timeInterval = this.timeInterval;
      var timeHighlight = this.timeHighlight;
      var hoverMode = this.hoverMode;

      if (timeInterval !== this.oldTimeInterval) {
        this.updateInterval();
      }

      if (hoverMode !== this.oldHoverMode) {
        this.onHoverModeUpdate();
      }

      if (selection !== this.oldSelection || timeInterval !== this.oldTimeInterval || timeHighlight !== this.oldTimeHighlight) {
        this.draw();
      } else {
        this.updateCrosshair();
      }

      this.set('oldSelection', selection);
      this.set('oldTimeInterval', timeInterval);
      this.set('oldTimeHighlight', timeHighlight);
      this.set('oldHoverMode', hoverMode);
    },
    update: function update() {
      this.updateTimeHighlight();

      this._super.apply(this, arguments);
    },
    draw: function draw() {
      this.updateCrosshair();

      this._super.apply(this, arguments);
    },
    updateCrosshair: function updateCrosshair() {
      var flot = this.flot,
          time = this.time;

      if (time === null) {
        flot.clearCrosshair();
      } else if (time === -1) {
        flot.lockCrosshair({
          x: 999999999
        });
      } else {
        flot.lockCrosshair({
          x: time * 1000
        });
      }
    },
    updateInterval: function updateInterval() {
      var flot = this.flot,
          interval = this.timeInterval;
      var opt = flot.getOptions();

      if (!interval) {
        opt.xaxes[0].min = opt.xaxes[0].max = null;
      } else {
        var _interval = (0, _slicedToArray2.default)(interval, 2),
            start = _interval[0],
            end = _interval[1];

        opt.xaxes[0].min = start * 1000;
        opt.xaxes[0].max = end * 1000;
      }
    },
    onHoverModeUpdate: function onHoverModeUpdate() {
      var _this2 = this;

      var placeholder = this.placeholder;

      if (this.hoverMode) {
        placeholder.on('plothover', function (event, pos) {
          _this2.set('time', pos.x / 1000);
        });
        placeholder.on('mouseout', function () {
          _this2.set('time', _this2.defaultTime);
        });
      } else {
        placeholder.off('plothover');
        placeholder.off('mouseout');
      }
    },
    updateTimeHighlight: function updateTimeHighlight() {
      // There is no flot.setOptions(), so we modify them in-place.
      var options = this.flot.getOptions(); // Clear the markings if there is no time highlight

      var time_highlight = this.timeHighlight;

      if (!time_highlight) {
        options.grid.markings = [];
        return;
      } // Add time highlight as flot markings


      options.grid.markings = [{
        color: '#fff083',
        xaxis: {
          from: time_highlight.start * 1000,
          to: time_highlight.end * 1000
        }
      }];
    }
  });

  _exports.default = _default;
});