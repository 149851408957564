define("skylines/templates/components/flight-leg-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yncm/yfH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[10,\"class\",\"start\"],[8],[1,[22,\"num\"],false],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"numeric\"],[8],[1,[28,\"format-distance\",[[24,[\"leg\",\"distance\"]]],[[\"decimals\"],[1]]],false],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"duration hidden-md\"],[8],[1,[28,\"format-seconds\",[[24,[\"leg\",\"duration\"]]],null],false],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"numeric\"],[8],[1,[28,\"format-speed\",[[24,[\"speed\"]]],null],false],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"numeric\"],[8],[1,[28,\"format-number\",[[24,[\"climbPercentage\"]]],[[\"style\",\"maximumFractionDigits\"],[\"percent\",1]]],false],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"numeric\"],[8],[4,\"if\",[[28,\"is-undefined\",[[24,[\"climbRate\"]]],null]],null,{\"statements\":[[0,\"-\"]],\"parameters\":[]},{\"statements\":[[1,[28,\"format-lift\",[[24,[\"climbRate\"]]],null],false]],\"parameters\":[]}],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"numeric\"],[8],[1,[28,\"format-number\",[[24,[\"glideRate\"]]],[[\"maximumFractionDigits\"],[1]]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/flight-leg-row.hbs"
    }
  });

  _exports.default = _default;
});