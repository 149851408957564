define("skylines/components/plane-label-overlay", ["exports", "jquery", "openlayers"], function (_exports, _jquery, _openlayers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    map: null,
    flight: null,
    position: null,
    overlay: null,
    init: function init() {
      this._super.apply(this, arguments);

      var badgeStyle = "display: inline-block; text-align: center; background: ".concat(this.get('flight.color'));
      var id = this.getWithDefault('flight.competition_id', '') || this.getWithDefault('flight.registration', '');
      var badge = (0, _jquery.default)("<span class=\"badge plane_marker\" style=\"".concat(badgeStyle, "\">\n      ").concat(id, "\n    </span>"));
      this.set('overlay', new _openlayers.default.Overlay({
        element: badge.get(0)
      }));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.overlay.setPosition(this.position);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var overlay = this.overlay;
      this.map.addOverlay(overlay);
      var width = (0, _jquery.default)(overlay.getElement()).width();
      overlay.setOffset([-width / 2, -40]);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var overlay = this.overlay;
      this.map.removeOverlay(overlay);
    }
  });

  _exports.default = _default;
});