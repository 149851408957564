define("skylines/routes/groupflights/all", ["exports", "skylines/routes/groupflights/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    getURL: function getURL() {
      return '/api/groupflights/all';
    }
  });

  _exports.default = _default;
});