define("skylines/components/fix-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flights: null,
    fixes: null,
    selection: null,
    data: Ember.computed('fixes.@each.flight', function () {
      return this.fixes.map(function (fix, i) {
        var flight = fix.get('flight');
        var id = flight.get('id');
        var color = flight.get('color');
        var competitionId = flight.get('competition_id') || flight.get('registration');
        var score = flight.get('score') * 1000;
        var distance = flight.get('distance');
        var triangleDistance = flight.get('triangleDistance');
        var removable = i !== 0;
        return {
          id: id,
          color: color,
          competitionId: competitionId,
          score: score,
          distance: distance,
          triangleDistance: triangleDistance,
          removable: removable,
          fix: fix
        };
      });
    }),
    selectable: Ember.computed('data.[]', function () {
      return this.data.length > 1;
    }),
    actions: {
      select: function select(id) {
        this.set('selection', this.selection === id ? null : id);
      }
    }
  });

  _exports.default = _default;
});