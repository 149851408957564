define("skylines/components/base-map", ["exports", "jquery", "openlayers", "skylines/config/environment"], function (_exports, _jquery, _openlayers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mapSettings: Ember.inject.service(),
    attributeBindings: ['style'],
    width: '100%',
    height: '100%',
    style: Ember.computed('width', 'height', function () {
      return Ember.String.htmlSafe("width: ".concat(this.width, "; height: ").concat(this.height, "; position: relative"));
    }),
    mapSettingsObserver: Ember.observer('mapSettings.baseLayer', 'mapSettings.overlayLayers.[]', function () {
      Ember.run.once(this, 'updateLayerVisibilities');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      window.flightMap = this;

      var interactions = _openlayers.default.interaction.defaults({
        altShiftDragRotate: false,
        pinchRotate: false
      });

      var map = new _openlayers.default.Map({
        view: new _openlayers.default.View({
          center: _openlayers.default.proj.transform([10, 50], 'EPSG:4326', 'EPSG:3857'),
          maxZoom: 17,
          zoom: 5
        }),
        controls: _openlayers.default.control.defaults().extend([new _openlayers.default.control.ScaleLine()]),
        interactions: interactions,
        ol3Logo: false
      });
      this.set('map', map);
      map.getViewport().setAttribute('tabindex', '0');
      (0, _jquery.default)(map.getViewport()).click(function () {
        (0, _jquery.default)(this).focus();
      });
      var osm_layer = new _openlayers.default.layer.Tile({
        source: new _openlayers.default.source.OSM(),
        zIndex: 1
      });
      osm_layer.setProperties({
        name: 'OpenStreetMap',
        id: 'OpenStreetMap',
        base_layer: true,
        display_in_layer_switcher: true
      });
      map.addLayer(osm_layer);
      this.addReliefLayer(); //    this.addAirspaceLayers();
      //    this.addMWPLayers();
      //    this.addBingLayers();

      this.addMapboxLayer();
      this.addEmptyLayer();
      this.updateLayerVisibilities();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var map = this.map;

      if (map) {
        map.setTarget(this.elementId);
      }
    },
    updateLayerVisibilities: function updateLayerVisibilities() {
      var mapSettings = this.mapSettings;
      var baseLayerNames = mapSettings.get('baseLayer');
      var overlayLayerNames = mapSettings.get('overlayLayers');
      var layers = this.map.getLayers().getArray().filter(function (layer) {
        return layer.get('display_in_layer_switcher');
      });
      var baseLayers = layers.filter(function (layer) {
        return layer.get('base_layer');
      });
      baseLayers.forEach(function (layer) {
        layer.setVisible(layer.get('name') === baseLayerNames);
      });

      if (!baseLayers.find(function (layer) {
        return layer.get('name') === baseLayerNames;
      })) {
        baseLayers.find(function (layer) {
          return layer.get('name') === 'OpenStreetMap';
        }).setVisible(true);
      }

      var overlayLayers = layers.filter(function (layer) {
        return !layer.get('base_layer');
      });
      overlayLayers.forEach(function (layer) {
        layer.setVisible(overlayLayerNames.includes(layer.get('name')));
      });
    },
    addMWPLayers: function addMWPLayers() {
      var mwp_layer = new _openlayers.default.layer.Tile({
        source: new _openlayers.default.source.XYZ({
          attributions: [new _openlayers.default.Attribution({
            html: 'Mountain Wave Data &copy; ' + '<a href="http://www.mountain-wave-project.com/">' + 'Mountain Wave Project' + '</a>.'
          })],
          url: "".concat(_environment.default.SKYLINES_TILE_BASEURL || '', "/tiles/1.0.0/mwp/EPSG3857/{z}/{x}/{y}.png")
        }),
        zIndex: 11
      });
      mwp_layer.setProperties({
        name: 'Mountain Wave Project',
        id: 'MountainWaveProject',
        base_layer: false,
        display_in_layer_switcher: true
      });
      this.map.addLayer(mwp_layer);
    },
    addAirspaceLayers: function addAirspaceLayers() {
      var airspace_layer = new _openlayers.default.layer.Tile({
        source: new _openlayers.default.source.XYZ({
          url: "".concat(_environment.default.SKYLINES_TILE_BASEURL || '', "/tiles/1.0.0/airspace+airports/EPSG3857/{z}/{x}/{y}.png")
        }),
        zIndex: 10
      });
      airspace_layer.setProperties({
        name: 'Airspace',
        id: 'Airspace',
        base_layer: false,
        display_in_layer_switcher: true
      });
      this.map.addLayer(airspace_layer);
    },
    addReliefLayer: function addReliefLayer() {
      var url = 'http://maps-for-free.com/layer/relief/z{z}/row{y}/{z}_{x}-{y}.jpg';
      var relief_layer = new _openlayers.default.layer.Tile({
        source: new _openlayers.default.source.XYZ({
          attributions: [new _openlayers.default.Attribution({
            html: 'SRTM relief maps from <a target="_blank" rel="noopener" ' + 'href="http://maps-for-free.com/">maps-for-free.com</a>'
          })],
          url: url
        }),
        zIndex: 2
      });
      relief_layer.setProperties({
        name: 'Shaded Relief',
        id: 'ShadedRelief',
        base_layer: true,
        display_in_layer_switcher: true
      });
      this.map.addLayer(relief_layer);
    },
    addMapboxLayer: function addMapboxLayer() {
      var tile_url = _environment.default.MAPBOX_TILE_URL;

      if (!tile_url) {
        return;
      }

      var mapbox_layer = new _openlayers.default.layer.Tile({
        source: new _openlayers.default.source.XYZ({
          attributions: [new _openlayers.default.Attribution({
            html: '<a href="https://www.mapbox.com/about/maps/"' + ' target="_blank" rel="noopener">' + '&copy; Mapbox &copy; OpenStreetMap</a> <a' + ' class="mapbox-improve-map"' + ' href="https://www.mapbox.com/map-feedback/"' + ' target="_blank" rel="noopener">Improve this map</a>'
          })],
          url: tile_url
        }),
        zIndex: 5
      });
      mapbox_layer.setProperties({
        name: 'Terrain',
        id: 'Terrain',
        base_layer: true,
        display_in_layer_switcher: true
      });
      this.map.addLayer(mapbox_layer);
    },
    addBingLayers: function addBingLayers() {
      var api_key = _environment.default.BING_API_KEY;

      if (!api_key) {
        return;
      } // Bing's Road imagerySet


      var road = new _openlayers.default.layer.Tile({
        source: new _openlayers.default.source.BingMaps({
          key: api_key,
          imagerySet: 'Road'
        }),
        zIndex: 3
      });
      road.setProperties({
        name: 'Bing Road',
        id: 'BingRoad',
        base_layer: true,
        display_in_layer_switcher: true
      }); // Bing's AerialWithLabels imagerySet

      var hybrid = new _openlayers.default.layer.Tile({
        source: new _openlayers.default.source.BingMaps({
          key: api_key,
          imagerySet: 'AerialWithLabels'
        }),
        zIndex: 4
      });
      hybrid.setProperties({
        name: 'Bing Satellite',
        id: 'BingSatellite',
        base_layer: true,
        display_in_layer_switcher: true
      });
      var map = this.map;
      map.addLayer(road);
      map.addLayer(hybrid);
    },
    addEmptyLayer: function addEmptyLayer() {
      var empty_layer = new _openlayers.default.layer.Tile({});
      empty_layer.setProperties({
        name: 'Empty',
        id: 'Empty',
        base_layer: true,
        display_in_layer_switcher: true
      });
      this.map.addLayer(empty_layer);
    }
  });

  _exports.default = _default;
});