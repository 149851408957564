define("skylines/components/cesium-plane-model", ["exports", "openlayers", "skylines/computed/safe-computed"], function (_exports, _openlayers, _safeComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    scene: null,
    fix: null,
    entity: null,
    position: (0, _safeComputed.default)('coordinate', function (coordinate) {
      var lonlat = _openlayers.default.proj.transform(coordinate, 'EPSG:3857', 'EPSG:4326');

      return Cesium.Cartesian3.fromDegrees(lonlat[0], lonlat[1], lonlat[2]);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('entity', Cesium.Model.fromGltf({
        url: '../../3d/AS21.glb',
        scale: 1,
        minimumPixelSize: 64,
        allowPicking: false
      }));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.entity.modelMatrix = Cesium.Transforms.headingPitchRollToFixedFrame(this.position, new Cesium.HeadingPitchRoll(this.heading - Math.PI / 2, 0, 0));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.scene.primitives.add(this.entity);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.scene.primitives.remove(this.entity);
    }
  });

  _exports.default = _default;
});