define("skylines/templates/components/timeline-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qJ+/XZC5",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"component\",[[24,[\"eventComponent\"]]],[[\"event\"],[[24,[\"event\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/timeline-event.hbs"
    }
  });

  _exports.default = _default;
});