define("skylines/components/sortable-table-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    column: null,
    defaultOrder: 'asc',
    cssClass: Ember.computed('class', function () {
      var _class = this['class'];

      if (_class) {
        return "".concat(_class, " sortable");
      } else {
        return 'sortable';
      }
    })
  });

  _exports.default = _default;
});