define("skylines/templates/components/base-barogram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hZ25Tlwn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"style\",[22,\"flotStyle\"]],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/base-barogram.hbs"
    }
  });

  _exports.default = _default;
});