define("skylines/validators/not-equal", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var NotEqualValidator = _base.default.extend({
    intl: Ember.inject.service(),
    validate: function validate(value1, options, model) {
      var value2 = model.get(options.on);
      return Ember.isNone(value1) || Ember.isNone(value2) || value1 !== value2 ? true : this.intl.t(options.messageKey);
    }
  });

  NotEqualValidator.reopenClass({
    getDependentsFor: function getDependentsFor(attribute, options) {
      return ["_model.".concat(options.on)];
    }
  });
  var _default = NotEqualValidator;
  _exports.default = _default;
});