define("skylines/components/pin-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pinnedFlights: Ember.inject.service(),
    tagName: '',
    pinned: Ember.computed('pinnedFlights.pinned.[]', 'flightId', function () {
      return this.pinnedFlights.pinned.includes(this.flightId);
    }),
    actions: {
      toggle: function toggle() {
        this.pinnedFlights.toggle(this.flightId);
      }
    }
  });

  _exports.default = _default;
});