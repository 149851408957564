define("skylines/components/fix-table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNameBindings: ['selected', 'selectable'],
    selectable: false,
    badgeStyle: Ember.computed('row.color', function () {
      return Ember.String.htmlSafe("background-color: ".concat(this.row.color));
    }),
    actions: {
      remove: function remove() {
        this.onRemove(this.get('row.id'));
      }
    },
    click: function click() {
      if (this.selectable) {
        this.onSelect(this.get('row.id'));
      }
    }
  });

  _exports.default = _default;
});