define("skylines/templates/components/user-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BnuPulx3",
    "block": "{\"symbols\":[\"@user\"],\"statements\":[[5,\"base-page\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"page-header\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-8 col-md-offset-2\"],[8],[0,\"\\n      \"],[5,\"user-details\",[],[[\"@user\"],[[23,1,[]]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-6 col-md-4 col-md-offset-2\"],[8],[0,\"\\n      \"],[5,\"user-distance-flights\",[],[[\"@flights\"],[[23,1,[\"distanceFlights\"]]]]],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-6 col-md-4\"],[8],[0,\"\\n      \"],[5,\"user-quick-stats\",[],[[\"@stats\"],[[23,1,[\"stats\"]]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[\"takeoffLocations\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n        \"],[7,\"h2\",true],[8],[1,[28,\"t\",[\"takeoff-locations\"],null],false],[9],[0,\"\\n        \"],[5,\"takeoffs-map\",[],[[\"@locations\",\"@height\"],[[23,1,[\"takeoffLocations\"]],\"533px\"]]],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/user-page.hbs"
    }
  });

  _exports.default = _default;
});