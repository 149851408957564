define("skylines/templates/components/settings-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SVdQcfVy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,\"base-page\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"page-header\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[1,[22,\"title\"],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-8 col-sm-push-4 col-md-9 col-md-push-3\"],[8],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-4 col-sm-pull-8 col-md-3 col-md-pull-9\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"panel panel-default\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"panel-heading\"],[8],[0,\"\\n          \"],[7,\"h3\",true],[10,\"class\",\"panel-title\"],[8],[1,[24,[\"account\",\"user\",\"name\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"list-group\"],[8],[0,\"\\n          \"],[5,\"link-to\",[[12,\"class\",\"list-group-item\"]],[[\"@route\"],[\"settings.profile\"]],{\"statements\":[[1,[28,\"t\",[\"profile\"],null],false]],\"parameters\":[]}],[0,\"\\n          \"],[5,\"link-to\",[[12,\"class\",\"list-group-item\"]],[[\"@route\"],[\"settings.password\"]],{\"statements\":[[1,[28,\"t\",[\"password\"],null],false]],\"parameters\":[]}],[0,\"\\n          \"],[5,\"link-to\",[[12,\"class\",\"list-group-item\"]],[[\"@route\"],[\"settings.club\"]],{\"statements\":[[1,[28,\"t\",[\"club\"],null],false]],\"parameters\":[]}],[0,\"\\n\"],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/settings-page.hbs"
    }
  });

  _exports.default = _default;
});