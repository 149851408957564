define("skylines/templates/components/stats-flights-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NJSEztn+",
    "block": "{\"symbols\":[\"year\",\"p\"],\"statements\":[[7,\"thead\",true],[8],[0,\"\\n\"],[7,\"tr\",true],[8],[0,\"\\n  \"],[7,\"th\",true],[8],[1,[28,\"t\",[\"year\"],null],false],[9],[0,\"\\n  \"],[7,\"th\",true],[10,\"colspan\",\"2\"],[8],[1,[28,\"t\",[\"flights\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"years\"]]],null,{\"statements\":[[0,\"  \"],[7,\"tr\",true],[8],[0,\"\\n    \"],[7,\"td\",true],[10,\"class\",\"col-xs-2\"],[8],[1,[23,1,[\"year\"]],false],[9],[0,\"\\n    \"],[7,\"td\",true],[10,\"class\",\"col-xs-2\"],[8],[1,[28,\"format-number\",[[23,1,[\"flights\"]]],null],false],[9],[0,\"\\n    \"],[7,\"td\",true],[10,\"class\",\"col-xs-8\"],[8],[0,\"\\n      \"],[5,\"bs-progress\",[],[[],[]],{\"statements\":[[0,\"\\n        \"],[6,[23,2,[\"bar\"]],[],[[\"@value\"],[[28,\"percent\",[[23,1,[\"flights\"]],[24,[\"max\"]]],null]]]],[0,\"\\n      \"]],\"parameters\":[2]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[7,\"tr\",true],[8],[0,\"\\n  \"],[7,\"td\",true],[8],[7,\"strong\",true],[8],[1,[28,\"t\",[\"total\"],null],false],[9],[9],[0,\"\\n  \"],[7,\"td\",true],[10,\"colspan\",\"2\"],[8],[7,\"strong\",true],[8],[1,[28,\"format-number\",[[24,[\"sum\"]]],null],false],[9],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/stats-flights-table.hbs"
    }
  });

  _exports.default = _default;
});