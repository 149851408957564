define("skylines/templates/components/timeline-events/new-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9hYtPoUE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[10,\"class\",\"icon\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fa fa-asterisk small\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[8],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"btn btn-default btn-sm pull-right\"]],[[\"@route\",\"@model\"],[\"user\",[24,[\"event\",\"actor\",\"id\"]]]],{\"statements\":[[1,[28,\"t\",[\"show\"],null],false]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    \"],[4,\"if\",[[24,[\"event\",\"unread\"]]],null,{\"statements\":[[7,\"span\",true],[10,\"class\",\"badge badge-warning\"],[8],[1,[28,\"t\",[\"new\"],null],false],[9]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"small\",true],[8],[7,\"abbr\",true],[11,\"title\",[28,\"format-time\",[[24,[\"event\",\"time\"]]],[[\"format\"],[\"full\"]]]],[8],[1,[28,\"format-relative\",[[24,[\"event\",\"time\"]]],null],false],[9],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[[24,[\"translationKey\"]]],[[\"htmlSafe\",\"actor\",\"actorURL\"],[true,[24,[\"event\",\"actor\",\"name\"]],[28,\"href-to\",[\"user\",[24,[\"event\",\"actor\",\"id\"]]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/timeline-events/new-user.hbs"
    }
  });

  _exports.default = _default;
});