define("skylines/components/nav-bar-menu-right", ["exports", "skylines/utils/locales"], function (_exports, _locales) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    account: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    tagName: '',
    availableLocales: _locales.default,
    currentLocale: Ember.computed('availableLocales.@each.code', 'intl.locale', function () {
      return this.availableLocales.findBy('code', this.intl.locale[0]);
    }),
    actions: {
      setLocale: function setLocale(locale) {
        this.intl.loadAndSetLocale(locale);
      }
    }
  });

  _exports.default = _default;
});