define("skylines/templates/components/follower-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3o4Z0eNQ",
    "block": "{\"symbols\":[\"follower\",\"@followers\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"row list-unstyled\"],[8],[0,\"\\n\"],[4,\"each\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[10,\"class\",\"col-md-3 col-sm-4 col-xs-6\"],[8],[0,\"\\n      \"],[5,\"follower-panel\",[],[[\"@follower\"],[[23,1,[]]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/follower-list.hbs"
    }
  });

  _exports.default = _default;
});