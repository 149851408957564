define("skylines/templates/components/nav-bar-search-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dtMF3VZt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\",true],[10,\"action\",\"/search\"],[10,\"class\",\"navbar-form navbar-left navbar-search visible-lg\"],[11,\"onsubmit\",[28,\"action\",[[23,0,[]],[24,[\"onSearch\"]],[24,[\"searchTextService\",\"text\"]]],null]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[5,\"input\",[[12,\"placeholder\",[28,\"t\",[\"search.search\"],null]],[12,\"class\",\"form-control\"]],[[\"@type\",\"@name\",\"@value\"],[\"text\",\"text\",[24,[\"searchTextService\",\"text\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/nav-bar-search-form.hbs"
    }
  });

  _exports.default = _default;
});