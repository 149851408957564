define("skylines/components/cesium-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['CesiumSwitcher', 'ol-unselectable'],
    enabled: false,
    onEnable: function onEnable() {},
    onDisable: function onDisable() {},
    click: function click() {
      this.toggle();
    },
    toggle: function toggle() {
      if (this.enabled) {
        this.onDisable();
      } else {
        this.onEnable();
      }
    }
  });

  _exports.default = _default;
});