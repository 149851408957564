define("skylines/components/fullscreen-button", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['FullscreenButton', 'ol-unselectable'],
    click: function click() {
      this.toggle();
    },
    toggle: function toggle() {
      var element = this.fullscreenElement;
      BigScreen.toggle((0, _jquery.default)(element)[0]);
    }
  });

  _exports.default = _default;
});