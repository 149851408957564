define("skylines/routes/groupflights/-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    queryParams: {
      page: {
        refreshModel: true
      },
      column: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return this.ajax.request(this.getURL(params), {
        data: {
          page: params.page,
          column: params.column,
          order: params.order
        }
      });
    },
    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      if (isExiting) {
        controller.set('page', 1);
      }
    },
    actions: {
      loading: function loading(transition) {
        var controller = this.controllerFor('groupflights');
        controller.set('loading', true);
        transition.promise.finally(function () {
          controller.set('loading', false);
        });
      }
    },
    getURL: function getURL()
    /* params */
    {
      throw new Error('Not implemented: `getURL`');
    }
  });

  _exports.default = _default;
});