define("skylines/routes/groupflight/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel() {
      var ids = this.modelFor('groupflight').ids;
      var gf_id = this.modelFor('groupflight').groupflight.id;
      this.transitionTo('flight', gf_id + ':' + ids.join(',')); //Groupflights start url with <groupflight_id>:
    }
  });

  _exports.default = _default;
});