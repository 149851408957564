define("skylines/components/circling-performance-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    left: findBy('perf', 'circlingDirection', 'left'),
    right: findBy('perf', 'circlingDirection', 'right'),
    mixed: findBy('perf', 'circlingDirection', 'mixed'),
    total: findBy('perf', 'circlingDirection', 'total')
  });

  _exports.default = _default;

  function findBy(array, key, value) {
    return Ember.computed("".concat(array, ".@each.").concat(key), function () {
      return this[array].findBy(key, value);
    });
  }
});