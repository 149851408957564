define("skylines/templates/components/following-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vpG0R7l6",
    "block": "{\"symbols\":[\"@user\",\"@following\"],\"statements\":[[5,\"base-page\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"page-header\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[1,[23,1,[\"name\"]],false],[0,\" - \"],[1,[28,\"t\",[\"following\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n      \"],[5,\"follower-list\",[],[[\"@followers\"],[[23,2,[]]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/following-page.hbs"
    }
  });

  _exports.default = _default;
});