define("skylines/components/flight-list-nav", ["exports", "skylines/computed/safe-computed", "skylines/utils/add-days"], function (_exports, _safeComputed, _addDays) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    account: Ember.inject.service(),
    pinnedFlights: Ember.inject.service(),
    tagName: '',
    hasPinned: Ember.computed.notEmpty('pinnedFlights.pinned'),
    prevDate: (0, _safeComputed.default)('date', function (date) {
      return (0, _addDays.default)(date, -1);
    }),
    nextDate: (0, _safeComputed.default)('date', function (date) {
      return (0, _addDays.default)(date, 1);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('router', Ember.getOwner(this).lookup('router:main'));
    },
    actions: {
      dateSelected: function dateSelected(date) {
        this.router.transitionTo('flights.date', date);
      }
    }
  });

  _exports.default = _default;
});