define("skylines/components/head-layout", ["exports", "ember-cli-head/templates/components/head-layout"], function (_exports, _headLayout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    headElement: Ember.computed(function () {
      var documentService = Ember.getOwner(this).lookup('service:-document');
      return documentService.head;
    }),
    layout: _headLayout.default
  });

  _exports.default = _default;
});