define("skylines/components/phase-highlight-composer", ["exports", "openlayers", "skylines/computed/computed-point"], function (_exports, _openlayers, _computedPoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    map: null,
    paddingFn: null,
    coordinates: null,
    calculatePadding: function calculatePadding() {},
    startCoordinate: Ember.computed.readOnly('coordinates.firstObject'),
    endCoordinate: Ember.computed.readOnly('coordinates.lastObject'),
    startPoint: (0, _computedPoint.default)('coordinates.firstObject'),
    endPoint: (0, _computedPoint.default)('coordinates.lastObject'),
    coordinatesObserver: Ember.observer('coordinates.[]', function () {
      this.adjustMapView();
      Ember.run.once(this.map, 'render');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var startIcon = new _openlayers.default.style.Icon({
        anchor: [0.5, 1],
        src: '/images/marker-green.png'
      });
      startIcon.load();
      var endIcon = new _openlayers.default.style.Icon({
        anchor: [0.5, 1],
        src: '/images/marker.png'
      });
      endIcon.load();
      var startStyle = new _openlayers.default.style.Style({
        image: startIcon
      });
      this.set('startStyle', startStyle);
      var endStyle = new _openlayers.default.style.Style({
        image: endIcon
      });
      this.set('endStyle', endStyle); // activate coordinatesObserver

      this.get('coordinates');
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.map.on('postcompose', this.onPostCompose, this);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.map.un('postcompose', this.onPostCompose, this);
    },
    onPostCompose: function onPostCompose(e) {
      this.renderMarkers(e.vectorContext);
    },
    renderMarkers: function renderMarkers(context) {
      this.renderMarker(context, this.startStyle, this.startPoint);
      this.renderMarker(context, this.endStyle, this.endPoint);
    },
    renderMarker: function renderMarker(context, style, coordinate) {
      if (coordinate) {
        context.setStyle(style);
        context.drawGeometry(coordinate);
      }
    },
    adjustMapView: function adjustMapView() {
      var coordinates = this.coordinates;

      if (coordinates) {
        var map = this.map;

        var extent = _openlayers.default.extent.boundingExtent(coordinates);

        var padding = this.calculatePadding();
        map.getView().fit(extent, {
          padding: padding
        });
      }
    }
  });

  _exports.default = _default;
});