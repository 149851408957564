define("skylines/components/layer-switcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mapSettings: Ember.inject.service(),
    classNames: ['GraphicLayerSwitcher', 'ol-unselectable'],
    map: null,
    open: false,
    baseLayers: null,
    overlayLayers: null,
    actions: {
      open: function open() {
        this.updateLayers();
        this.set('open', true);
      },
      select: function select(layer) {
        var mapSettings = this.mapSettings;

        if (layer.isBaseLayer) {
          mapSettings.setBaseLayer(layer.name);
        } else {
          mapSettings.toggleOverlayLayer(layer.name);
        }

        this.updateLayers();
      }
    },
    updateLayers: function updateLayers() {
      var mapSettings = this.mapSettings;
      var layers = this.map.getLayers().getArray().filter(function (layer) {
        return layer.get('display_in_layer_switcher');
      }).map(function (layer) {
        var id = layer.get('id');
        var name = layer.get('name');
        var visible = mapSettings.isLayerVisible(name);
        var isBaseLayer = layer.get('base_layer');
        return {
          id: id,
          name: name,
          visible: visible,
          isBaseLayer: isBaseLayer
        };
      });
      this.set('baseLayers', layers.filter(function (layer) {
        return layer.isBaseLayer;
      }));
      this.set('overlayLayers', layers.filter(function (layer) {
        return !layer.isBaseLayer;
      }));
    }
  });

  _exports.default = _default;
});