define("skylines/templates/groupflights/pinned", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rs27EzfF",
    "block": "{\"symbols\":[],\"statements\":[[5,\"groupflight-list-nav\",[],[[],[]]],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"pull-right\"],[8],[0,\"\\n  \"],[5,\"x-pager\",[],[[\"@count\",\"@page\",\"@perPage\"],[[24,[\"model\",\"count\"]],[22,\"page\"],50]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"groupflight-list\",[],[[\"@groupflights\",\"@showTimes\",\"@sortColumn\",\"@sortOrder\"],[[24,[\"model\",\"groupflights\"]],false,[22,\"column\"],[22,\"order\"]]]],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"pull-right\"],[8],[0,\"\\n  \"],[5,\"x-pager\",[],[[\"@count\",\"@page\",\"@perPage\"],[[24,[\"model\",\"count\"]],[22,\"page\"],50]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/groupflights/pinned.hbs"
    }
  });

  _exports.default = _default;
});