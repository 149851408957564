define("skylines/templates/club/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uqVcAdEm",
    "block": "{\"symbols\":[],\"statements\":[[5,\"form-page\",[],[[\"@title\"],[[28,\"t\",[\"edit-club\"],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"club-edit-form\",[],[[\"@club\"],[[22,\"club\"]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/club/edit.hbs"
    }
  });

  _exports.default = _default;
});