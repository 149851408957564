define("skylines/components/validated-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-group has-feedback'],
    classNameBindings: ['_showErrorClass:has-error', 'isValid:has-success'],
    validation: null,
    label: null,
    hasContent: true,
    forceErrorClass: false,
    didValidate: false,
    notValidating: Ember.computed.not('validation.isValidating'),
    isValid: Ember.computed.and('hasContent', 'validation.isValid', 'notValidating'),
    isInvalid: Ember.computed.readOnly('validation.isInvalid'),
    showErrorClass: Ember.computed.and('notValidating', 'showMessage', 'hasContent', 'validation'),
    _showMessage: Ember.computed.or('validation.isDirty', 'didValidate'),
    showMessage: Ember.computed.and('_showMessage', 'isInvalid'),
    _showErrorClass: Ember.computed.or('showErrorClass', 'forceErrorClass')
  });

  _exports.default = _default;
});