define("skylines/routes/ranking/airports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    queryParams: {
      page: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var data = {
        year: this.paramsFor('ranking').year,
        page: params.page
      };
      return this.ajax.request('/api/ranking/airports', {
        data: data
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('year', this.paramsFor('ranking').year);
    },
    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      if (isExiting) {
        controller.set('page', 1);
      }
    },
    actions: {
      loading: function loading(transition) {
        var controller = this.controllerFor('ranking');
        controller.set('loading', true);
        transition.promise.finally(function () {
          controller.set('loading', false);
        });
      }
    }
  });

  _exports.default = _default;
});