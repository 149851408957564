define("skylines/templates/components/validated-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a0dh1Gyb",
    "block": "{\"symbols\":[],\"statements\":[[5,\"validated-block\",[[12,\"class\",[22,\"class\"]]],[[\"@label\",\"@validation\",\"@hasContent\",\"@forceErrorClass\",\"@didValidate\"],[[22,\"label\"],[22,\"validation\"],[22,\"value\"],[22,\"forceErrorClass\"],[22,\"didValidate\"]]],{\"statements\":[[0,\"\\n  \"],[5,\"input\",[[12,\"placeholder\",[22,\"placeholder\"]],[12,\"class\",\"form-control\"]],[[\"@type\",\"@value\",\"@disabled\",\"@autocomplete\"],[[22,\"type\"],[22,\"value\"],[22,\"disabled\"],[22,\"autocomplete\"]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/validated-input.hbs"
    }
  });

  _exports.default = _default;
});