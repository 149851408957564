define("skylines/templates/components/layer-switcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hs9yxWSC",
    "block": "{\"symbols\":[\"layer\",\"layer\"],\"statements\":[[4,\"if\",[[24,[\"open\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"id\",\"GraphicLayerSwitcher-open\"],[3,\"on-click-outside\",[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"open\"]]],null],false],null]],[[\"eventType\"],[\"mousedown\"]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"base_layers\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"baseLayers\"]]],null,{\"statements\":[[0,\"        \"],[5,\"layer-switcher-element\",[],[[\"@layer\",\"@onSelect\"],[[23,2,[]],[28,\"action\",[[23,0,[]],\"select\",[23,2,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"overlay_layers\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"overlayLayers\"]]],null,{\"statements\":[[0,\"        \"],[5,\"layer-switcher-element\",[],[[\"@layer\",\"@onSelect\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"select\",[23,1,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"id\",\"GraphicLayerSwitcher-closed\"],[8],[0,\"\\n    \"],[7,\"button\",false],[3,\"action\",[[23,0,[]],\"open\"]],[8],[7,\"img\",true],[10,\"src\",\"../../images/layers.png\"],[8],[9],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/layer-switcher.hbs"
    }
  });

  _exports.default = _default;
});