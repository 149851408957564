define("skylines/templates/components/datetime-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RwRtUWBl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[10,\"class\",\"form-control\"],[8],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"input-group-addon\"],[8],[7,\"i\",true],[10,\"class\",\"fa fa-clock-o\"],[10,\"aria-hidden\",\"true\"],[8],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/datetime-picker.hbs"
    }
  });

  _exports.default = _default;
});