define("skylines/helpers/includes", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.includes = includes;
  _exports.default = void 0;

  function includes(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        array = _ref2[0],
        value = _ref2[1];

    return array.includes(value);
  }

  var _default = Ember.Helper.helper(includes);

  _exports.default = _default;
});