define("skylines/templates/users/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S5OkZccm",
    "block": "{\"symbols\":[],\"statements\":[[5,\"form-page\",[],[[\"@title\"],[[28,\"t\",[\"register\"],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"register-form\",[],[[\"@transitionTo\"],[[28,\"action\",[[23,0,[]],\"transitionTo\"],null]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/users/new.hbs"
    }
  });

  _exports.default = _default;
});