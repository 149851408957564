define("skylines/utils/fix", ["exports", "openlayers", "skylines/computed/computed-point", "skylines/computed/safe-computed", "skylines/utils/geo-distance", "skylines/utils/next-smaller-index"], function (_exports, _openlayers, _computedPoint, _safeComputed, _geoDistance, _nextSmallerIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Fix = Ember.Object.extend({
    fixCalc: null,
    _t: Ember.computed.readOnly('fixCalc.time'),
    t: Ember.computed('_t', 'flight.{startTime,endTime}', function () {
      var _t = this._t;

      if (_t === -1) {
        return this.get('flight.endTime');
      } else if (_t >= this.get('flight.startTime') && _t <= this.get('flight.endTime')) {
        return _t;
      }
    }),
    _index: (0, _safeComputed.default)('t', 'flight.time', function (t, time) {
      return (0, _nextSmallerIndex.default)(time, t);
    }),
    t_prev: (0, _safeComputed.default)('_index', 'flight.time', function (index, time) {
      return time[index];
    }),
    t_next: (0, _safeComputed.default)('_index', 'flight.time', function (index, time) {
      return time[index + 1];
    }),
    time: Ember.computed.readOnly('t_prev'),
    coordinate: (0, _safeComputed.default)('t', 'flight.geometry', function (time, geometry) {
      return geometry.getCoordinateAtM(time);
    }),
    lon: Ember.computed.readOnly('coordinate.0'),
    lat: Ember.computed.readOnly('coordinate.1'),
    'alt-msl': (0, _safeComputed.default)('coordinate.2', 'flight.geoid', function (altitude, geoid) {
      return altitude - geoid;
    }),
    'alt-gnd': (0, _safeComputed.default)('alt-msl', 'elevation', function (altitude, elevation) {
      var value = altitude - elevation;
      return value >= 0 ? value : 0;
    }),
    point: (0, _computedPoint.default)('coordinate'),
    pointXY: (0, _computedPoint.default)('coordinate', 'XY'),
    heading: (0, _safeComputed.default)('_coordinate_prev', '_coordinate_next', function (prev, next) {
      return Math.atan2(next[0] - prev[0], next[1] - prev[1]);
    }),
    vario: (0, _safeComputed.default)('_coordinate_prev.2', '_coordinate_next.2', '_dt', function (prev, next, dt) {
      return (next - prev) / dt;
    }),
    speed: (0, _safeComputed.default)('_coordinate_prev', '_coordinate_next', '_dt', function (prev, next, dt) {
      var loc_prev = _openlayers.default.proj.transform(prev, 'EPSG:3857', 'EPSG:4326');

      var loc_next = _openlayers.default.proj.transform(next, 'EPSG:3857', 'EPSG:4326');

      return (0, _geoDistance.default)(loc_next, loc_prev) / dt;
    }),
    _dt: (0, _safeComputed.default)('t_prev', 't_next', function (prev, next) {
      return next - prev;
    }),
    _coordinate_prev: (0, _safeComputed.default)('t_prev', 'flight.geometry', function (time, geometry) {
      return geometry.getCoordinateAtM(time);
    }),
    _coordinate_next: (0, _safeComputed.default)('t_next', 'flight.geometry', function (time, geometry) {
      return geometry.getCoordinateAtM(time);
    }),
    elevation: (0, _safeComputed.default)('flight.elev_h', '_elev_index', function (elev_h, index) {
      return elev_h[index];
    }),
    _elev_index: (0, _safeComputed.default)('flight.elev_t', 't', function (elev_t, t) {
      return (0, _nextSmallerIndex.default)(elev_t, t);
    })
  });
  Fix[Ember.NAME_KEY] = 'Fix';
  var _default = Fix;
  _exports.default = _default;
});