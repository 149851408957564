define("skylines/utils/contest", ["exports", "openlayers"], function (_exports, _openlayers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Dictionary of contest names and their colors.
   */
  var CONTEST_COLORS = {
    'olc_plus classic': '#ff2c73',
    'olc_plus triangle': '#9f14ff'
  };
  /**
   * A contest of a flight.
   * @constructor
   * @param {Array<Object>} _contest Scored/Optimised contests.
   *   Such an object must contain: name, turnpoints, times
   *   turnpoints and times are googlePolyEncoded strings.
   * @param {Number} _sfid The SkyLines flight id this contest trace belongs to.
   */

  var slContest = Ember.Object.extend();

  slContest.fromData = function (_contest, flightId) {
    var turnpoints = _openlayers.default.format.Polyline.decodeDeltas(_contest.turnpoints, 2);

    var times = _openlayers.default.format.Polyline.decodeDeltas(_contest.times, 1, 1);

    var name = _contest.name;
    var geometry = new _openlayers.default.geom.LineString([]);
    var turnpointsLength = turnpoints.length;
    var triangle = name.search(/triangle/) !== -1 && turnpointsLength === 5 * 2;

    if (triangle) {
      for (var i = 2; i < turnpointsLength - 2; i += 2) {
        var point = _openlayers.default.proj.transform([turnpoints[i + 1], turnpoints[i]], 'EPSG:4326', 'EPSG:3857');

        geometry.appendCoordinate(point);
      }

      geometry.appendCoordinate(geometry.getFirstCoordinate());
    } else {
      for (var _i = 0; _i < turnpointsLength; _i += 2) {
        var _point = _openlayers.default.proj.transform([turnpoints[_i + 1], turnpoints[_i]], 'EPSG:4326', 'EPSG:3857');

        geometry.appendCoordinate(_point);
      }
    }

    var color = CONTEST_COLORS[name] || '#ff2c73';
    return slContest.create({
      flightId: flightId,
      times: times,
      name: name,
      geometry: geometry,
      color: color
    });
  };

  var _default = slContest;
  _exports.default = _default;
});