define("skylines/templates/statistics/airport", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ss9kw9HX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[8],[1,[28,\"t\",[\"flights\"],null],false],[9],[0,\"\\n\"],[5,\"stats-flights-table\",[],[[\"@years\"],[[24,[\"model\",\"years\"]]]]],[0,\"\\n\\n\"],[7,\"h2\",true],[8],[1,[28,\"t\",[\"distance\"],null],false],[9],[0,\"\\n\"],[5,\"stats-distance-table\",[],[[\"@years\"],[[24,[\"model\",\"years\"]]]]],[0,\"\\n\\n\"],[7,\"h2\",true],[8],[1,[28,\"t\",[\"flight-time\"],null],false],[9],[0,\"\\n\"],[5,\"stats-duration-table\",[],[[\"@years\"],[[24,[\"model\",\"years\"]]]]],[0,\"\\n\\n\"],[7,\"h2\",true],[8],[1,[28,\"t\",[\"pilots\"],null],false],[9],[0,\"\\n\"],[5,\"stats-pilots-table\",[],[[\"@years\",\"@sumPilots\"],[[24,[\"model\",\"years\"]],[24,[\"model\",\"sumPilots\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/statistics/airport.hbs"
    }
  });

  _exports.default = _default;
});