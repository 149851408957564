define("skylines/templates/components/nav-bar-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xKefvcx7",
    "block": "{\"symbols\":[\"@nav\"],\"statements\":[[6,[23,1,[\"item\"]],[[12,\"class\",[22,\"listClass\"]]],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[24,[\"nav\",\"link-to\"]],\"expected `nav.link-to` to be a contextual component but found a string. Did you mean `(component nav.link-to)`? ('skylines/templates/components/nav-bar-link.hbs' @ L2:C5) \"],null],[24,[\"target\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[11,\"class\",[29,[\"fa fa-lg fa-\",[22,\"icon\"],\" visible-sm-inline-block\"]]],[11,\"title\",[22,\"title\"]],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n    \"],[7,\"i\",true],[11,\"class\",[29,[\"fa fa-\",[22,\"icon\"],\" fa-fw visible-xs-inline-block\"]]],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"hidden-sm\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/nav-bar-link.hbs"
    }
  });

  _exports.default = _default;
});