define("skylines/templates/flights/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9IvkUmcb",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n      \"],[7,\"h2\",true],[8],[1,[28,\"t\",[\"scoringinfo.olc+\"],null],false],[9],[0,\"\\n      \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"scoringinfo.olc-history\"],null],false],[9],[0,\"\\n      \"],[7,\"h4\",true],[8],[1,[28,\"t\",[\"distance\"],null],false],[9],[0,\"\\n      \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"scoringinfo.distance\"],null],false],[9],[0,\"\\n      \"],[7,\"h4\",true],[8],[1,[28,\"t\",[\"triangle\"],null],false],[9],[0,\"\\n      \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"scoringinfo.triangle\"],null],false],[9],[0,\"\\n      \"],[7,\"h4\",true],[8],[1,[28,\"t\",[\"index\"],null],false],[9],[0,\"\\n      \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"scoringinfo.index\"],null],false],[9],[0,\"\\n      \"],[7,\"h4\",true],[8],[1,[28,\"t\",[\"score\"],null],false],[9],[0,\"\\n      \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"scoringinfo.score\"],null],false],[9],[0,\"\\n\\n\"],[0,\"\\n\"],[0,\"\\n\"],[0,\"\\n\"],[0,\"\\n\"],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/flights/info.hbs"
    }
  });

  _exports.default = _default;
});