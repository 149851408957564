define("skylines/routes/statistics/-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model: function model(params) {
      return this.ajax.request(this.getURL(params));
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var routeName = this.routeName;
      var params = this.paramsFor(routeName);
      this.controllerFor('statistics').setProperties({
        airport: routeName === 'statistics.airport' ? parseInt(params.airport_id, 10) : null,
        pilot: routeName === 'statistics.pilot' ? parseInt(params.pilot_id, 10) : null,
        club: routeName === 'statistics.club' ? parseInt(params.club_id, 10) : null,
        name: Ember.get(model, 'name')
      });
    },
    getURL: function getURL()
    /* params */
    {
      throw new Error('Not implemented: `getURL`');
    }
  });

  _exports.default = _default;
});