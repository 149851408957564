define("skylines/helpers/format-hours", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "skylines/utils/pad"], function (_exports, _slicedToArray2, _pad) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatHours = formatHours;
  _exports.default = void 0;

  function formatHours(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        value = _ref2[0];

    var h = Math.floor(value / 3600);
    var m = Math.floor(value % 3600 / 60);
    return "".concat(h, ":").concat((0, _pad.default)(m));
  }

  var _default = Ember.Helper.helper(formatHours);

  _exports.default = _default;
});