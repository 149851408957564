define("skylines/components/contest-layer", ["exports", "openlayers"], function (_exports, _openlayers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_COLOR = '#004bbd';

  var _default = Ember.Component.extend({
    tagName: '',
    map: null,
    flights: null,
    contests: Ember.computed('flights.@each.contests', function () {
      return this.flights.map(function (flight) {
        return flight.get('contests');
      }).reduce(function (a, b) {
        return a.concat(b);
      }, []);
    }),
    layer: Ember.computed(function () {
      return new _openlayers.default.layer.Vector({
        source: new _openlayers.default.source.Vector(),
        style: style_function,
        name: 'Contest',
        zIndex: 49
      });
    }),
    source: Ember.computed('layer', function () {
      return this.layer.getSource();
    }),
    visible: Ember.computed({
      get: function get() {
        return this.layer.getVisible();
      },
      set: function set(key, value) {
        this.layer.setVisible(value);
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.map.addLayer(this.layer);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.map.removeLayer(this.layer);
    }
  });
  /**
   * Determin the drawing style for the feature
   * @param {ol.feature} feature Feature to style
   * @return {!Array<ol.style.Style>} Style of the feature
   */


  _exports.default = _default;

  function style_function(feature) {
    var color = DEFAULT_COLOR;

    if (feature.getKeys().includes('color')) {
      color = feature.get('color');
    }

    return [new _openlayers.default.style.Style({
      stroke: new _openlayers.default.style.Stroke({
        color: color,
        width: 2,
        lineDash: [5]
      }),
      zIndex: 999
    })];
  }
});