define("skylines/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      search: function search(text) {
        this.transitionToRoute('search', {
          queryParams: {
            text: text
          }
        });
        return false;
      }
    }
  });

  _exports.default = _default;
});