define("skylines/components/upload-barogram", ["exports", "openlayers", "skylines/components/base-barogram"], function (_exports, _openlayers, _baseBarogram) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseBarogram.default.extend({
    units: Ember.inject.service(),
    uploadMode: true,
    height: 160,
    flight: null,
    trace: null,
    date: Ember.computed.readOnly('flight.igcFile.date'),
    takeoffTime: null,
    scoreStartTime: null,
    scoreEndTime: null,
    landingTime: null,
    takeoffTimeSeconds: computedSecondsOfDay('date', 'takeoffTime'),
    scoreStartTimeSeconds: computedSecondsOfDay('date', 'scoreStartTime'),
    scoreEndTimeSeconds: computedSecondsOfDay('date', 'scoreEndTime'),
    landingTimeSeconds: computedSecondsOfDay('date', 'landingTime'),
    onTakeoffTimeChange: function onTakeoffTimeChange() {},
    onScoreStartTimeChange: function onScoreStartTimeChange() {},
    onScoreEndTimeChange: function onScoreEndTimeChange() {},
    onLandingTimeChange: function onLandingTimeChange() {},
    init: function init() {
      this._super.apply(this, arguments);

      var units = this.units;

      var height = _openlayers.default.format.Polyline.decodeDeltas(this.get('trace.barogram_h'), 1, 1);

      var time = _openlayers.default.format.Polyline.decodeDeltas(this.get('trace.barogram_t'), 1, 1);

      var enl = _openlayers.default.format.Polyline.decodeDeltas(this.get('trace.enl'), 1, 1);

      var _elev_h = _openlayers.default.format.Polyline.decodeDeltas(this.get('trace.elevations_h'), 1, 1);

      var flot_h = [];
      var flot_enl = [];
      var flot_elev = [];
      var timeLength = time.length;

      for (var i = 0; i < timeLength; ++i) {
        var timestamp = time[i] * 1000;
        flot_h.push([timestamp, units.convertAltitude(height[i])]);
        flot_enl.push([timestamp, enl[i]]);
        var e = _elev_h[i];

        if (e < -500) {
          e = null;
        }

        flot_elev.push([timestamp, e ? units.convertAltitude(e) : null]);
      }

      var color = '#004bbd';
      this.set('active', [{
        data: flot_h,
        color: color
      }]);
      this.set('enls', [{
        data: flot_enl,
        color: color
      }]);
      this.set('elevations', flot_elev);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.placeholder.on('plotselecting', function (event, range, marker) {
        var date = secondsToDate(_this.date, range[marker]);

        if (marker === 'takeoff') {
          _this.onTakeoffTimeChange(date);
        } else if (marker === 'scoring_start') {
          _this.onScoreStartTimeChange(date);
        } else if (marker === 'scoring_end') {
          _this.onScoreEndTimeChange(date);
        } else if (marker === 'landing') {
          _this.onLandingTimeChange(date);
        }
      });
      this.updateSelection();
      this.draw();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.updateSelection();
    },
    updateSelection: function updateSelection() {
      var takeoff = this.takeoffTimeSeconds;
      var scoring_start = this.scoreStartTimeSeconds;
      var scoring_end = this.scoreEndTimeSeconds;
      var landing = this.landingTimeSeconds;
      this.flot.setSelection({
        takeoff: takeoff,
        scoring_start: scoring_start,
        scoring_end: scoring_end,
        landing: landing
      }, true);
    }
  });

  _exports.default = _default;

  function computedSecondsOfDay(dateKey, timeKey) {
    return Ember.computed(dateKey, timeKey, function () {
      var date = new Date(this.get(dateKey));
      date.setUTCHours(0, 0, 0, 0);
      var time = new Date(this.get(timeKey));
      return time.getTime() - date.getTime();
    });
  }

  function secondsToDate(date, seconds) {
    date = new Date(date);
    date.setUTCHours(0, 0, 0, 0);
    return new Date(date.getTime() + seconds);
  }
});