define("skylines/components/wingman-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNames: ['selectable'],
    flight: Ember.computed.alias('nearFlight.flight'),
    igcFile: Ember.computed.alias('flight.igcFile'),
    pilot: Ember.computed.alias('flight.pilot'),
    pilotName: Ember.computed.or('flight.{pilot.name,pilotName}'),
    copilot: Ember.computed.alias('flight.copilot'),
    copilotName: Ember.computed.or('flight.{copilot.name,copilotName}'),
    times: Ember.computed.alias('nearFlight.times'),
    colorStripeStyle: Ember.computed('nearFlight.color', function () {
      return Ember.String.htmlSafe("background-color: ".concat(this.nearFlight.color));
    })
  });

  _exports.default = _default;
});