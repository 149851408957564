define("skylines/templates/components/base-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vE+44UO+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\\n\"],[7,\"p\",true],[10,\"class\",\"footer\"],[8],[0,\"\\n  © \"],[5,\"link-to\",[],[[\"@route\"],[\"about.team\"]],{\"statements\":[[1,[28,\"t\",[\"the-skylines-team\"],null],false]],\"parameters\":[]}],[0,\"\\n  -\\n  \"],[5,\"link-to\",[],[[\"@route\"],[\"about.imprint\"]],{\"statements\":[[1,[28,\"t\",[\"imprint\"],null],false]],\"parameters\":[]}],[0,\"\\n  -\\n  \"],[7,\"a\",true],[10,\"href\",\"https://github.com/hess8/skylinesC/blob/master/PRIVACY.md\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[8],[1,[28,\"t\",[\"privacy-policy\"],null],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/base-page.hbs"
    }
  });

  _exports.default = _default;
});