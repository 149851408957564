define("skylines/services/pinned-flights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    cookies: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.load();
    },
    pin: function pin(id) {
      this.set('pinned', this.pinned.concat([id]));
      this.save();
    },
    unpin: function unpin(id) {
      this.set('pinned', this.pinned.without(id));
      this.save();
    },
    load: function load() {
      var pinned = [];
      var cookie = this.cookies.read('SkyLines_pinnedFlights');

      if (cookie) {
        pinned = cookie.split(',').map(function (it) {
          return parseInt(it, 10);
        });
      }

      this.set('pinned', pinned);
    },
    save: function save() {
      this.cookies.write('SkyLines_pinnedFlights', this.pinned.join(','), {
        path: '/'
      });
    },
    toggle: function toggle(id) {
      if (this.pinned.includes(id)) {
        this.unpin(id);
      } else {
        this.pin(id);
      }
    }
  });

  _exports.default = _default;
});