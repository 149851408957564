define("skylines/templates/components/circling-performance-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o8z7YcJD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"th\",true],[8],[1,[28,\"t\",[[28,\"concat\",[\"circling-direction.\",[24,[\"perf\",\"circlingDirection\"]]],null]],null],false],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"numeric\"],[8],[0,\"\\n  \"],[1,[24,[\"perf\",\"count\"]],false],[7,\"br\",true],[8],[9],[0,\"\\n  \"],[1,[28,\"format-lift\",[[24,[\"perf\",\"vario\"]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"numeric\"],[8],[0,\"\\n  \"],[7,\"abbr\",true],[11,\"title\",[29,[[28,\"format-number\",[[24,[\"perf\",\"fraction\"]]],[[\"maximumFractionDigits\"],[1]]]]]],[8],[1,[28,\"format-seconds\",[[24,[\"perf\",\"duration\"]]],null],false],[9],[7,\"br\",true],[8],[9],[0,\"\\n  \"],[1,[28,\"format-altitude\",[[24,[\"perf\",\"altDiff\"]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/circling-performance-row.hbs"
    }
  });

  _exports.default = _default;
});