define("skylines/components/takeoffs-layer-feature", ["exports", "openlayers"], function (_exports, _openlayers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    source: null,
    location: null,
    feature: Ember.computed(function () {
      var location = this.location;

      var transformed = _openlayers.default.proj.transform(location, 'EPSG:4326', 'EPSG:3857');

      return new _openlayers.default.Feature({
        geometry: new _openlayers.default.geom.Point(transformed)
      });
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.source.addFeature(this.feature);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.source.removeFeature(this.feature);
    }
  });

  _exports.default = _default;
});