define("skylines/templates/components/pin-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mHMHZymL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\",true],[11,\"class\",[29,[\"fa fa-\",[28,\"if\",[[24,[\"pinned\"]],\"star\",\"star-o\"],null]]]],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n\"],[5,\"bs-tooltip\",[],[[\"@placement\"],[\"left\"]],{\"statements\":[[0,\"Activate this to show the flight on top of other flights on the map\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/pin-star.hbs"
    }
  });

  _exports.default = _default;
});