define("skylines/components/stats-pilots-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'table',
    classNames: ['table', 'table-condensed', 'table-striped'],
    years: null,
    sumPilots: null,
    pilots: Ember.computed.mapBy('years', 'pilots'),
    max: Ember.computed.max('pilots'),
    sum: Ember.computed.readOnly('sumPilots')
  });

  _exports.default = _default;
});