define("skylines/components/groupflight-page", ["exports", "jquery", "skylines/utils/fix-calc"], function (_exports, _jquery, _fixCalc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    pinnedFlights: Ember.inject.service(),
    units: Ember.inject.service(),
    classNames: ['relative-fullscreen'],
    fixCalc: null,
    //  flightPhase: null,
    timeInterval: Ember.computed('mapExtent', 'cesiumEnabled', function () {
      if (this.cesiumEnabled) {
        return null;
      }

      var extent = this.mapExtent;

      if (!extent) {
        return null;
      }

      var interval = this.get('fixCalc.flights').getMinMaxTimeInExtent(extent);
      return interval.max === -Infinity ? null : [interval.min, interval.max];
    }),
    init: function init() {
      this._super.apply(this, arguments);

      console.log(this.club.name);
      var ajax = this.ajax;
      var units = this.units; //    let data = this.data

      var fixCalc = _fixCalc.default.create({
        ajax: ajax,
        units: units
      });

      if (this.firstPath) {
        console.log('firstPath'), console.log(this.firstPath.sfid);
      } else {
        console.log('not firstPath');
      }

      if (this.firstData) {
        console.log('firstData'), console.log(this.firstData.flight.id);
      } else {
        console.log('not firstData');
      }

      fixCalc.addFlight(this.firstPath);
      this.set('fixCalc', fixCalc);
      console.log('test1b');
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var fixCalc = this.fixCalc;
      var sidebar = this.element.querySelector('#sidebar');
      var $sidebar = (0, _jquery.default)(sidebar).sidebar();
      var olScaleLine = this.element.querySelector('.ol-scale-line');
      var olAttribution = this.element.querySelector('.ol-attribution');

      if (window.location.hash && sidebar.querySelector("li > a[href=\"#".concat(window.location.hash.substring(1), "\"]"))) {
        $sidebar.open(window.location.hash.substring(1));
      } else if (window.innerWidth >= 768) {
        $sidebar.open('tab-overview');
      }

      var map = window.flightMap.get('map'); //add rest of paths
      //    this.ids.slice(1).forEach(id => fixCalc.addFlightFromJSON(`/api/flights/${id}/json`));  //(1) includes 1 to end

      var extent = fixCalc.get('flights').getBounds();
      map.getView().fit(extent, {
        padding: this._calculatePadding()
      });
      this.get('pinnedFlights.pinned').filter(function (id) {
        return id !== primaryId;
      }).forEach(function (id) {
        return fixCalc.addFlightFromJSON("/api/flights/".concat(id, "/json"));
      });
    },
    actions: {
      togglePlayback: function togglePlayback() {
        console.log('test2');
        this.fixCalc.togglePlayback();
      },
      addFlight: function addFlight(data) {
        this.fixCalc.addFlight(data);
      },
      removeFlight: function removeFlight(id) {
        var flights = this.get('fixCalc.flights');
        flights.removeObjects(flights.filterBy('id', id));
        this.pinnedFlights.unpin(id);
      },
      calculatePadding: function calculatePadding() {
        return this._calculatePadding();
      }
    },
    //end actions
    _calculatePadding: function _calculatePadding() {
      var sidebar = this.element.querySelector('#sidebar'); //    let barogramPanel = this.element.querySelector('#barogram_panel');

      return [20, 20, 20, sidebar.offsetWidth + 20];
    }
  });

  _exports.default = _default;
});