define("skylines/templates/components/tracking-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BI8zBv0b",
    "block": "{\"symbols\":[\"track\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"table table-striped table-tracking\"],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n  \"],[7,\"tr\",true],[8],[0,\"\\n    \"],[7,\"th\",true],[10,\"class\",\"hidden-xs date\"],[8],[1,[28,\"t\",[\"last-fix\"],null],false],[9],[0,\"\\n    \"],[7,\"th\",true],[8],[1,[28,\"t\",[\"pilot\"],null],false],[9],[0,\"\\n    \"],[7,\"th\",true],[8],[1,[28,\"t\",[\"nearest-airport\"],null],false],[9],[0,\"\\n    \"],[7,\"th\",true],[10,\"class\",\"hidden-xs numeric\"],[8],[1,[28,\"t\",[\"altitude\"],null],false],[9],[0,\"\\n    \"],[7,\"th\",true],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"tracks\"]]],null,{\"statements\":[[0,\"    \"],[5,\"tracking-table-row\",[],[[\"@track\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/tracking-table.hbs"
    }
  });

  _exports.default = _default;
});