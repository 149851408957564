define("skylines/components/playback-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['PlayButton', 'ol-unselectable'],
    onToggle: function onToggle() {},
    click: function click() {
      this.toggle();
    },
    toggle: function toggle() {
      this.onToggle();
    }
  });

  _exports.default = _default;
});