define("skylines/components/contest-layer-feature", ["exports", "openlayers"], function (_exports, _openlayers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    source: null,
    contest: null,
    feature: Ember.computed(function () {
      var contest = this.contest;
      return new _openlayers.default.Feature({
        geometry: contest.get('geometry'),
        sfid: contest.get('flightId'),
        color: contest.get('color'),
        type: 'contest'
      });
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.source.addFeature(this.feature);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.source.removeFeature(this.feature);
    }
  });

  _exports.default = _default;
});