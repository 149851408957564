define("skylines/components/flight-leg-row", ["exports", "skylines/computed/safe-computed"], function (_exports, _safeComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNames: ['small', 'selectable'],
    classNameBindings: ['selected'],
    inf: Infinity,
    leg: null,
    selection: null,
    onSelect: function onSelect() {},
    speed: Ember.computed('leg.{duration,distance}', function () {
      var duration = this.get('leg.duration');

      if (duration > 0) {
        return this.get('leg.distance') / duration;
      } else {
        return 0;
      }
    }),
    climbPercentage: Ember.computed('leg.{duration,climbDuration}', function () {
      var duration = this.get('leg.duration');

      if (duration > 0) {
        return this.get('leg.climbDuration') / duration;
      } else {
        return 0;
      }
    }),
    climbRate: Ember.computed('leg.{climbDuration,climbHeight}', function () {
      var duration = this.get('leg.climbDuration');

      if (duration > 0) {
        return this.get('leg.climbHeight') / duration;
      }
    }),
    glideRate: Ember.computed('leg.{cruiseDistance,cruiseHeight}', function () {
      var distance = this.get('leg.cruiseDistance');
      var height = this.get('leg.cruiseHeight');

      if (Math.abs(height) > 0 && distance && Math.abs(distance / height) < 1000) {
        return distance / -height;
      } else {
        return Infinity;
      }
    }),
    selected: (0, _safeComputed.default)('selection', function (selection) {
      var leg = this.leg;
      return selection.start === leg.start && selection.end === leg.start + leg.duration;
    }),
    click: function click() {
      var onSelect = this.onSelect;

      if (this.selected) {
        onSelect(null);
      } else {
        var leg = this.leg;
        onSelect({
          start: leg.start,
          end: leg.start + leg.duration
        });
      }
    }
  });

  _exports.default = _default;
});