define("skylines/components/season-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    year: null,
    init: function init() {
      this._super.apply(this, arguments);

      var year = new Date().getFullYear();
      this.set('recentYears', [0, 1, 2, 3, 4].map(function (i) {
        return year - i;
      }));
    }
  });

  _exports.default = _default;
});