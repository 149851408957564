define("skylines/utils/flight-from-data", ["exports", "openlayers", "skylines/utils/contest", "skylines/utils/flight"], function (_exports, _openlayers, _contest, _flight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(data, units) {
    var _time = _openlayers.default.format.Polyline.decodeDeltas(data.barogram_t, 1, 1);

    var _lonlat = _openlayers.default.format.Polyline.decodeDeltas(data.points, 2);

    var _height = _openlayers.default.format.Polyline.decodeDeltas(data.barogram_h, 1, 1);

    var _enl = _openlayers.default.format.Polyline.decodeDeltas(data.enl, 1, 1);

    var fixes = _time.map(function (timestamp, i) {
      return {
        time: timestamp,
        longitude: _lonlat[i * 2],
        latitude: _lonlat[i * 2 + 1],
        altitude: _height[i] + data.geoid,
        enl: _enl[i]
      };
    });

    var _elev_t = _openlayers.default.format.Polyline.decodeDeltas(data.elevations_t, 1, 1);

    var _elev_h = _openlayers.default.format.Polyline.decodeDeltas(data.elevations_h, 1, 1);

    var elevations = _elev_t.map(function (timestamp, i) {
      var elevation = _elev_h[i];
      return {
        time: timestamp,
        elevation: elevation > -500 ? elevation : null
      };
    });

    var additional = data.additional || {};
    var contests;

    if (data.contests) {
      contests = data.contests.map(function (it) {
        return _contest.default.fromData(it, data.sfid);
      });
    }

    return _flight.default.create({
      units: units,
      id: data.sfid,
      fixes: fixes,
      elevations: elevations,
      contests: contests,
      geoid: data.geoid,
      competition_id: additional.competition_id,
      groupflight_id: additional.groupflight_id,
      score: additional.score,
      distance: additional.distance,
      triangleDistance: additional.triangleDistance,
      registration: additional.registration,
      model: additional.model
    });
  }
});