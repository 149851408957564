define("skylines/routes/ranking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      year: {
        refreshModel: true
      }
    },
    model: function model() {}
  });

  _exports.default = _default;
});