define("skylines/components/tracking-tables", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    account: Ember.inject.service(),
    tagName: '',
    tracks: null,
    friends: null,
    friendsTracks: Ember.computed('tracks.[]', 'friends.[]', 'account.user.id', function () {
      var self = this.get('account.user.id');

      if (Ember.isNone(self)) {
        return [];
      }

      var friends = this.friends;
      return this.tracks.filter(function (track) {
        return track.pilot.id === self || friends.includes(track.pilot.id);
      });
    }),
    othersTracks: Ember.computed.setDiff('tracks', 'friendsTracks')
  });

  _exports.default = _default;
});