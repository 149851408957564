define("skylines/routes/settings/club", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model: function model() {
      return this.ajax.request('/api/clubs/choose-club');
    }
  });

  _exports.default = _default;
});