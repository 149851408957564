define("skylines/components/flight-page", ["exports", "@babel/runtime/helpers/esm/toArray", "jquery", "skylines/utils/fix-calc", "skylines/utils/flight-phase"], function (_exports, _toArray2, _jquery, _fixCalc, _flightPhase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    pinnedFlights: Ember.inject.service(),
    units: Ember.inject.service(),
    classNames: ['relative-fullscreen'],
    fixCalc: null,
    flightPhase: null,
    timeInterval: Ember.computed('mapExtent', 'cesiumEnabled', function () {
      if (this.cesiumEnabled) {
        return null;
      }

      var extent = this.mapExtent;

      if (!extent) {
        return null;
      }

      var interval = this.get('fixCalc.flights').getMinMaxTimeInExtent(extent);
      return interval.max === -Infinity ? null : [interval.min, interval.max];
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var ajax = this.ajax;
      var units = this.units;

      var fixCalc = _fixCalc.default.create({
        ajax: ajax,
        units: units
      });

      fixCalc.addFlight(this._primaryFlightPath);
      this.set('fixCalc', fixCalc);

      var flightPhase = _flightPhase.default.create({
        fixCalc: fixCalc
      });

      this.set('flightPhase', flightPhase);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var fixCalc = this.fixCalc;
      var sidebar = this.element.querySelector('#sidebar');
      var $sidebar = (0, _jquery.default)(sidebar).sidebar();
      var barogramPanel = this.element.querySelector('#barogram_panel');
      var $barogramPanel = (0, _jquery.default)(barogramPanel);
      var olScaleLine = this.element.querySelector('.ol-scale-line');
      var olAttribution = this.element.querySelector('.ol-attribution');

      var resize = function resize() {
        var bottom = Number(getComputedStyle(barogramPanel).bottom.replace('px', ''));
        var height = barogramPanel.offsetHeight + bottom;
        sidebar.style.bottom = "".concat(height, "px");
        olScaleLine.style.bottom = "".concat(height, "px");
        olAttribution.style.bottom = "".concat(height, "px");
      };

      resize();
      $barogramPanel.resize(resize);

      if (window.location.hash && sidebar.querySelector("li > a[href=\"#".concat(window.location.hash.substring(1), "\"]"))) {
        $sidebar.open(window.location.hash.substring(1));
      } else if (window.innerWidth >= 768) {
        $sidebar.open('tab-overview');
      }

      var _this$ids = (0, _toArray2.default)(this.ids),
          primaryId = _this$ids[0],
          otherIds = _this$ids.slice(1);

      var map = window.flightMap.get('map');
      otherIds.forEach(function (id) {
        return fixCalc.addFlightFromJSON("/api/groupflights/".concat(id, "/json"));
      });
      var extent = fixCalc.get('flights').getBounds();
      map.getView().fit(extent, {
        padding: this._calculatePadding()
      });
      this.get('pinnedFlights.pinned').filter(function (id) {
        return id !== primaryId;
      }).forEach(function (id) {
        return fixCalc.addFlightFromJSON("/api/groupflights/".concat(id, "/json"));
      });
    },
    actions: {
      togglePlayback: function togglePlayback() {
        this.fixCalc.togglePlayback();
      },
      addFlight: function addFlight(data) {
        this.fixCalc.addFlight(data);
      },
      removeFlight: function removeFlight(id) {
        var flights = this.get('fixCalc.flights');
        flights.removeObjects(flights.filterBy('id', id));
        this.pinnedFlights.unpin(id);
      },
      selectWingman: function selectWingman(id) {
        var fixCalc = this.fixCalc;
        var pinnedFlights = this.pinnedFlights;
        var flights = fixCalc.get('flights');
        var matches = flights.filterBy('id', id);

        if (matches.length !== 0) {
          flights.removeObjects(matches);
          pinnedFlights.unpin(id);
        } else {
          fixCalc.addFlightFromJSON("/api/groupflights/".concat(id, "/json"));
          pinnedFlights.pin(id);
        }
      },
      calculatePadding: function calculatePadding() {
        return this._calculatePadding();
      }
    },
    _calculatePadding: function _calculatePadding() {
      var sidebar = this.element.querySelector('#sidebar');
      var barogramPanel = this.element.querySelector('#barogram_panel');
      return [20, 20, barogramPanel.offsetHeight + 20, sidebar.offsetWidth + 20];
    }
  });

  _exports.default = _default;
});