define("skylines/components/cesium-scene", ["exports", "ol-cesium", "skylines/config/environment"], function (_exports, _olCesium, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    enabled: false,
    map: null,
    enabledObserver: Ember.observer('enabled', function () {
      Ember.run.once(this, 'update');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var ol3d = new _olCesium.default.OLCesium({
        map: this.map
      });
      Cesium.Ion.defaultAccessToken = _environment.default.CESIUM_TOKEN;
      var scene = ol3d.getCesiumScene();
      scene.terrainProvider = new Cesium.CesiumTerrainProvider({
        url: Cesium.IonResource.fromAssetId(1)
      });
      scene.globe.depthTestAgainstTerrain = true;
      this.set('ol3d', ol3d);
      this.set('scene', scene);
      this.update();
    },
    update: function update() {
      var enabled = this.enabled;
      this.ol3d.setEnabled(enabled);

      if (!enabled) {
        this.map.getView().setRotation(0);
      }
    }
  });

  _exports.default = _default;
});