define("skylines/helpers/truncate", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.truncate = truncate;
  _exports.default = void 0;

  function truncate(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        text = _ref2[0],
        length = _ref2[1];

    return text.length <= length ? text : "".concat(text.slice(0, length - 3), "...");
  }

  var _default = Ember.Helper.helper(truncate);

  _exports.default = _default;
});