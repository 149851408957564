define("skylines/templates/components/tracking-tables", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "15Q5oglj",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"friendsTracks\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"othersTracks\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h3\",true],[8],[1,[28,\"t\",[\"friends\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[5,\"tracking-table\",[],[[\"@tracks\"],[[22,\"friendsTracks\"]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"othersTracks\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"friendsTracks\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h3\",true],[8],[1,[28,\"t\",[\"other-pilots\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[5,\"tracking-table\",[],[[\"@tracks\"],[[22,\"othersTracks\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/tracking-tables.hbs"
    }
  });

  _exports.default = _default;
});