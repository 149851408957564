define("skylines/templates/components/flight-phase-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X3h5rU5m",
    "block": "{\"symbols\":[\"phase\"],\"statements\":[[7,\"table\",true],[10,\"id\",\"phases-table\"],[10,\"class\",\"table table-condensed table-striped phases-table-condensed\"],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[1,[28,\"t\",[\"start\"],null],false],[9],[0,\"\\n      \"],[7,\"th\",true],[10,\"class\",\"hidden-md\"],[8],[1,[28,\"t\",[\"duration\"],null],false],[9],[0,\"\\n      \"],[7,\"th\",true],[10,\"class\",\"numeric\"],[8],[0,\"ΔH\"],[7,\"br\",true],[8],[9],[0,\"[\"],[1,[24,[\"units\",\"altitudeUnit\"]],false],[0,\"]\"],[9],[0,\"\\n      \"],[7,\"th\",true],[10,\"class\",\"numeric\"],[8],[1,[28,\"t\",[\"distance-abbr\"],null],false],[7,\"br\",true],[8],[9],[0,\"[\"],[1,[24,[\"units\",\"distanceUnit\"]],false],[0,\"]\"],[9],[0,\"\\n      \"],[7,\"th\",true],[10,\"class\",\"numeric\"],[8],[1,[28,\"t\",[\"avg-vario-abbr\"],null],false],[7,\"br\",true],[8],[9],[0,\"[\"],[1,[24,[\"units\",\"liftUnit\"]],false],[0,\"]\"],[9],[0,\"\\n      \"],[7,\"th\",true],[10,\"class\",\"numeric\"],[8],[1,[28,\"t\",[\"avg-speed-abbr\"],null],false],[7,\"br\",true],[8],[9],[0,\"[\"],[1,[24,[\"units\",\"speedUnit\"]],false],[0,\"]\"],[9],[0,\"\\n      \"],[7,\"th\",true],[10,\"class\",\"numeric\"],[8],[7,\"abbr\",true],[11,\"title\",[28,\"t\",[\"glide-rate\"],null]],[8],[1,[28,\"t\",[\"glide-rate-abbr\"],null],false],[9],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"phases\"]]],null,{\"statements\":[[0,\"      \"],[5,\"flight-phase-row\",[],[[\"@phase\",\"@selection\",\"@onSelect\"],[[23,1,[]],[22,\"selection\"],[22,\"onSelect\"]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/flight-phase-table.hbs"
    }
  });

  _exports.default = _default;
});