define("skylines/templates/components/flight-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bZP7W/N3",
    "block": "{\"symbols\":[\"cesium\",\"&default\"],\"statements\":[[5,\"layer-switcher\",[],[[\"@map\"],[[22,\"map\"]]]],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"flights\"]]],null,{\"statements\":[[0,\"  \"],[5,\"cesium-button\",[],[[\"@enabled\",\"@onEnable\",\"@onDisable\"],[[22,\"cesiumEnabled\"],[28,\"action\",[[23,0,[]],\"cesiumEnabled\"],null],[28,\"action\",[[23,0,[]],\"cesiumDisabled\"],null]]]],[0,\"\\n  \"],[5,\"flight-path-layer\",[],[[\"@map\",\"@flights\"],[[22,\"map\"],[22,\"flights\"]]]],[0,\"\\n  \"],[5,\"phase-highlight-composer\",[],[[\"@map\",\"@coordinates\",\"@calculatePadding\"],[[22,\"map\"],[22,\"phaseHighlightCoords\"],[22,\"calculatePadding\"]]]],[0,\"\\n  \"],[5,\"plane-icons-composer\",[],[[\"@map\",\"@fixes\"],[[22,\"map\"],[22,\"fixes\"]]]],[0,\"\\n  \"],[5,\"contest-layer\",[],[[\"@map\",\"@flights\",\"@visible\"],[[22,\"map\"],[22,\"flights\"],[28,\"not\",[[24,[\"cesiumEnabled\"]]],null]]]],[0,\"\\n\\n\"],[4,\"if\",[[28,\"and\",[[28,\"not\",[[24,[\"cesiumEnabled\"]]],null],[28,\"gt\",[[24,[\"flights\",\"length\"]],1],null]],null]],null,{\"statements\":[[0,\"    \"],[5,\"plane-label-overlays\",[],[[\"@map\",\"@fixes\"],[[22,\"map\"],[22,\"fixes\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[5,\"cesium-loader\",[],[[\"@enabled\"],[[22,\"cesiumEnabled\"]]],{\"statements\":[[0,\"\\n  \"],[5,\"cesium-scene\",[],[[\"@enabled\",\"@map\"],[[22,\"cesiumEnabled\"],[22,\"map\"]]],{\"statements\":[[0,\"\\n    \"],[5,\"cesium-plane-models\",[],[[\"@scene\",\"@fixes\"],[[23,1,[\"scene\"]],[22,\"fixes\"]]]],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[14,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/flight-map.hbs"
    }
  });

  _exports.default = _default;
});