define("skylines/components/flight-map", ["exports", "skylines/utils/map-click-handler", "skylines/components/base-map"], function (_exports, _mapClickHandler, _baseMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseMap.default.extend({
    flights: null,
    fixes: null,
    phaseHighlightCoords: null,
    hoverEnabled: true,
    onExtentChange: function onExtentChange() {},
    onTimeChange: function onTimeChange() {},
    onCesiumEnabledChange: function onCesiumEnabledChange() {},
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var map = this.map;
      map.on('moveend', this._handleMoveEnd, this);
      map.on('pointermove', this._handlePointerMove, this);
      (0, _mapClickHandler.default)(this.map, this.flights, this.addFlight);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var map = this.map;
      map.un('moveend', this._handleMoveEnd, this);
      map.un('pointermove', this._handlePointerMove, this);
    },
    _handleMoveEnd: function _handleMoveEnd(event) {
      this.onExtentChange(event.frameState.extent);
    },
    _handlePointerMove: function _handlePointerMove(event) {
      if (event.dragging || !this.hoverEnabled) {
        return;
      }

      var map = this.map;
      var source = this.get('flights.source');
      var coordinate = map.getEventCoordinate(event.originalEvent);
      var feature = source.getClosestFeatureToCoordinate(coordinate);

      if (feature !== null) {
        var geometry = feature.getGeometry();
        var closest_point = geometry.getClosestPoint(coordinate);
        var feature_pixel = map.getPixelFromCoordinate(closest_point);
        var mouse_pixel = map.getPixelFromCoordinate(coordinate);
        var squared_distance = Math.pow(mouse_pixel[0] - feature_pixel[0], 2) + Math.pow(mouse_pixel[1] - feature_pixel[1], 2); // Set the time when the mouse hovers the map

        var time = squared_distance > 100 ? this.defaultTime : closest_point[3];
        this.onTimeChange(time);
        map.render();
      }
    },
    actions: {
      cesiumEnabled: function cesiumEnabled() {
        this.set('cesiumEnabled', true);
        this.onCesiumEnabledChange(true);
      },
      cesiumDisabled: function cesiumDisabled() {
        this.set('cesiumEnabled', false);
        this.onCesiumEnabledChange(false);
      }
    }
  });

  _exports.default = _default;
});