define("skylines/components/notifications-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    tagName: 'span',
    classNames: ['badge'],
    classNameBindings: ['notifications.hasUnread:badge-warning'],
    attributeBindings: ['title'],
    title: Ember.computed('intl.locale', function () {
      return this.intl.t('notifications');
    })
  });

  _exports.default = _default;
});