define("skylines/templates/components/contest-layer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MGRJEVFh",
    "block": "{\"symbols\":[\"contest\"],\"statements\":[[4,\"each\",[[24,[\"contests\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[5,\"contest-layer-feature\",[],[[\"@source\",\"@contest\"],[[22,\"source\"],[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/contest-layer.hbs"
    }
  });

  _exports.default = _default;
});