define("skylines/templates/about/imprint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O2BhHzfI",
    "block": "{\"symbols\":[],\"statements\":[[5,\"base-page\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"page-header\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"imprint\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n      \"],[1,[28,\"markdown\",[[24,[\"model\",\"content\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/about/imprint.hbs"
    }
  });

  _exports.default = _default;
});