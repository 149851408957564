define("skylines/initializers/promise-polyfill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    window.Promise = Ember.RSVP.Promise;
  }

  var _default = {
    name: 'promise-polyfill',
    initialize: initialize
  };
  _exports.default = _default;
});