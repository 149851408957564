define("skylines/controllers/about/team", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    text: Ember.computed('model.content', 'intl.locale', function () {
      var intl = this.intl;
      return this.get('model.content').replace('Developers', intl.t('developers')).replace('Translators', intl.t('translators'));
    })
  });

  _exports.default = _default;
});