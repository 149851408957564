define("skylines/components/cesium-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    cesiumLoader: Ember.inject.service(),
    tagName: '',
    enabled: false,
    loaded: false,
    enabledObserver: Ember.observer('enabled', function () {
      var _this = this;

      if (this.enabled && !this.loaded) {
        this.cesiumLoader.load().then(function () {
          _this.set('loaded', true);
        });
      }
    })
  });

  _exports.default = _default;
});