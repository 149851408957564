define("skylines/templates/components/comments-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fHnvmyIG",
    "block": "{\"symbols\":[\"comment\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"table\"],[10,\"style\",\"table-layout:fixed; word-wrap:break-word;\"],[8],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"comments\"]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"user\"]]],null,{\"statements\":[[0,\"            \"],[7,\"span\",true],[10,\"style\",\"float:left\"],[8],[0,\"\\n              \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"user\",[23,1,[\"user\",\"id\"]]]],{\"statements\":[[1,[23,1,[\"user\",\"name\"]],false]],\"parameters\":[]}],[0,\": \\n            \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n          \"],[1,[28,\"markdown\",[[23,1,[\"text\"]]],null],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[7,\"em\",true],[8],[1,[28,\"t\",[\"no-comments-yet\"],null],false],[9],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"account\",\"user\",\"id\"]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"\\n          \"],[7,\"form\",false],[3,\"action\",[[23,0,[]],[28,\"perform\",[[24,[\"addCommentTask\"]]],null]],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n            \"],[5,\"textarea\",[[12,\"class\",\"form-control\"]],[[\"@value\",\"@disabled\"],[[22,\"addCommentText\"],[24,[\"addCommentTask\",\"isRunning\"]]]]],[7,\"br\",true],[8],[9],[0,\"\\n            \"],[7,\"input\",true],[11,\"value\",[28,\"t\",[\"add-comment\"],null]],[10,\"class\",\"btn btn-primary\"],[11,\"disabled\",[24,[\"addCommentTask\",\"isRunning\"]]],[10,\"type\",\"submit\"],[8],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/comments-list.hbs"
    }
  });

  _exports.default = _default;
});