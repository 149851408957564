define("skylines/templates/components/pin-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jCpLJqFp",
    "block": "{\"symbols\":[],\"statements\":[[5,\"bs-button\",[],[[\"@onClick\"],[[28,\"action\",[[23,0,[]],\"toggle\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"pinned\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"fa fa-lg fa-star\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" \"],[1,[28,\"t\",[\"flight-pinned\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"fa fa-lg fa-star-o\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" \"],[1,[28,\"t\",[\"flight-unpinned\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/pin-button.hbs"
    }
  });

  _exports.default = _default;
});