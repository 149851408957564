define("skylines/controllers/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['text'],
    searchText: Ember.computed.readOnly('text'),
    searchTextInput: Ember.computed.oneWay('searchText'),
    results: Ember.computed.readOnly('model.results'),
    actions: {
      search: function search(text) {
        this.transitionToRoute({
          queryParams: {
            text: text
          }
        });
      }
    }
  });

  _exports.default = _default;
});