define("skylines/initializers/intl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    if (Intl.__disableRegExpRestore) {
      Intl.__disableRegExpRestore();
    }
  }

  var _default = {
    name: 'intl',
    initialize: initialize
  };
  _exports.default = _default;
});