define("skylines/templates/components/search-result-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t0oaCweI",
    "block": "{\"symbols\":[\"result\",\"@searchText\",\"@results\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"table table-striped search-results\"],[8],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,3,[]]],null,{\"statements\":[[0,\"    \"],[5,\"search-result-row\",[],[[\"@result\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"td\",true],[10,\"colspan\",\"4\"],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"t\",[\"search.no-results\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[28,\"t\",[\"search.no-search-text\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/search-result-table.hbs"
    }
  });

  _exports.default = _default;
});