define("skylines/templates/components/settings-panels/tracking-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bCOZd5qk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-heading\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"panel-title\"],[8],[1,[28,\"t\",[\"live-tracking-key\"],null],false],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"panel-help-icon\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"tracking.info\"]],{\"statements\":[[7,\"i\",true],[10,\"class\",\"fa fa-question fa-fw\"],[10,\"aria-hidden\",\"true\"],[8],[9]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[5,\"bs-alert\",[],[[\"@type\",\"@dismissible\"],[\"danger\",false]],{\"statements\":[[1,[22,\"error\"],false]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"p\",true],[10,\"style\",\"margin: 10px 0px 20px;\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"your-live-tracking-key\"],null],false],[0,\" \"],[7,\"span\",true],[10,\"class\",\"label tracking-key\"],[8],[1,[22,\"key\"],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"form\",false],[3,\"action\",[[23,0,[]],[28,\"perform\",[[24,[\"saveTask\"]]],null]],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n    \"],[7,\"button\",true],[10,\"class\",\"btn btn-primary btn-block\"],[11,\"disabled\",[24,[\"saveTask\",\"isRunning\"]]],[10,\"type\",\"submit\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[[28,\"if\",[[24,[\"saveTask\",\"isRunning\"]],\"generating-new-key\",\"generate-new-key\"],null]],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/settings-panels/tracking-key.hbs"
    }
  });

  _exports.default = _default;
});