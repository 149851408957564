define("skylines/routes/ranking/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect: function redirect() {
      this.replaceWith('ranking.clubs');
    }
  });

  _exports.default = _default;
});