define("skylines/components/flight-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'table',
    classNames: ['table', 'table-striped', 'table-condensed', 'table-flights'],
    flights: null,
    showDate: true,
    showLandscape: true,
    showAirport: true,
    showClub: true,
    showPilot: true,
    showTimes: true
  });

  _exports.default = _default;
});