define("skylines/templates/components/sortable-table-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xpd5YtAa",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"not-eq\",[[24,[\"column\"]],[24,[\"sortColumn\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"link-to\",[[12,\"class\",[22,\"cssClass\"]]],[[\"@query\",\"@tagName\"],[[28,\"hash\",null,[[\"column\",\"order\"],[[24,[\"column\"]],[24,[\"defaultOrder\"]]]]],\"th\"]],{\"statements\":[[14,1]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"sortOrder\"]],\"asc\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"link-to\",[[12,\"class\",[22,\"cssClass\"]]],[[\"@query\",\"@tagName\"],[[28,\"hash\",null,[[\"column\",\"order\"],[[24,[\"column\"]],\"desc\"]]],\"th\"]],{\"statements\":[[7,\"i\",true],[10,\"class\",\"fa fa-chevron-up\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" \"],[14,1]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"link-to\",[[12,\"class\",[22,\"cssClass\"]]],[[\"@query\",\"@tagName\"],[[28,\"hash\",null,[[\"column\",\"order\"],[[24,[\"column\"]],\"asc\"]]],\"th\"]],{\"statements\":[[7,\"i\",true],[10,\"class\",\"fa fa-chevron-down\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" \"],[14,1]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/sortable-table-header.hbs"
    }
  });

  _exports.default = _default;
});