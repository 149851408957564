define("skylines/templates/settings/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fLgHnwLd",
    "block": "{\"symbols\":[],\"statements\":[[5,\"settings-page\",[],[[\"@title\"],[[28,\"t\",[\"profile\"],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"settings-panels/profile\",[],[[\"@email\",\"@firstName\",\"@lastName\",\"@distanceUnitIndex\",\"@speedUnitIndex\",\"@liftUnitIndex\",\"@altitudeUnitIndex\"],[[24,[\"model\",\"email\"]],[24,[\"model\",\"firstName\"]],[24,[\"model\",\"lastName\"]],[24,[\"model\",\"distanceUnit\"]],[24,[\"model\",\"speedUnit\"]],[24,[\"model\",\"liftUnit\"]],[24,[\"model\",\"altitudeUnit\"]]]]],[0,\"\\n\\n  \"],[5,\"settings-panels/delete-account\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/settings/profile.hbs"
    }
  });

  _exports.default = _default;
});