define("skylines/templates/components/fullscreen-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tzdqaY2H",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\",true],[10,\"class\",\"fa fa-arrows-alt\"],[10,\"aria-hidden\",\"true\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/fullscreen-button.hbs"
    }
  });

  _exports.default = _default;
});