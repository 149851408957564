define("skylines/templates/settings/tracking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Et6hB41t",
    "block": "{\"symbols\":[],\"statements\":[[5,\"settings-page\",[],[[\"@title\"],[[28,\"t\",[\"live-tracking\"],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"settings-panels/tracking-key\",[],[[\"@key\"],[[24,[\"model\",\"trackingKey\"]]]]],[0,\"\\n  \"],[5,\"settings-panels/tracking\",[],[[\"@delay\",\"@callsign\"],[[24,[\"model\",\"trackingDelay\"]],[24,[\"model\",\"trackingCallsign\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/settings/tracking.hbs"
    }
  });

  _exports.default = _default;
});