define("skylines/routes/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    searchTextService: Ember.inject.service('searchText'),
    queryParams: {
      text: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var searchText = params.text;
      this.set('searchTextService.text', searchText);

      if (searchText) {
        return this.ajax.request("/api/search?text=".concat(searchText));
      }
    },
    actions: {
      loading: function loading(transition) {
        var controller = this.controllerFor('search');
        controller.set('currentlyLoading', true);
        transition.promise.finally(function () {
          controller.set('currentlyLoading', false);
        });
      }
    },
    deactive: function deactive() {
      this._super.apply(this, arguments);

      this.set('searchTextService.text', null);
    }
  });

  _exports.default = _default;
});