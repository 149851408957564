define("skylines/computed/safe-computed", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = safeComputed;

  function safeComputed(func) {
    var args;

    if (arguments.length > 1) {
      args = [].slice.call(arguments);
      func = args.pop();
    }

    args.push(function () {
      var values = [];

      for (var i = 0; i < args.length - 1; i++) {
        var value = this.get(args[i]); // drop out if any `value` is undefined

        if (Ember.isNone(value)) {
          return;
        }

        values.push(value);
      }

      return func.apply(this, values);
    });
    return Ember.computed.apply(void 0, (0, _toConsumableArray2.default)(args));
  }
});