define("skylines/components/date-picker", ["exports", "jquery", "skylines/utils/iso-date"], function (_exports, _jquery, _isoDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    date: null,
    onSelect: function onSelect() {},
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var picker = (0, _jquery.default)(this.element.querySelector('span')).datepicker({
        weekStart: 1
      });
      this.set('picker', picker);
      picker.on('changeDate', function (e) {
        picker.data('datepicker').hide();

        _this.onSelect((0, _isoDate.default)(e.date));
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var picker = this.picker;

      if (picker) {
        picker.off('changeDate');
      }

      this.set('picker', null);
    }
  });

  _exports.default = _default;
});