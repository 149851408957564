define("skylines/templates/components/validated-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pDVt3Imf",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[7,\"label\",true],[10,\"class\",\"control-label\"],[8],[1,[22,\"label\"],false],[9]],\"parameters\":[]},null],[0,\"\\n\"],[14,1],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showMessage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\",true],[10,\"class\",\"help-block\"],[8],[1,[24,[\"validation\",\"message\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/validated-block.hbs"
    }
  });

  _exports.default = _default;
});