define("skylines/components/groupflight-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'table',
    classNames: ['table', 'table-striped', 'table-condensed', 'table-flights'],
    groupflights: null,
    showDate: true,
    showAirport: true,
    showClub: true
  });

  _exports.default = _default;
});