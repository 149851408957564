define("skylines/components/groupflight-list-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr' //  classNameBindings: ['isPrivate:not_published'],
    //
    //  flight: null,
    //
    //  pilotName: or('flight.pilot.name', 'flight.pilotName'),
    //  copilotName: or('flight.copilot.name', 'flight.copilotName'),
    //
    //  isPublic: equal('flight.privacyLevel', 0),
    //  isPrivate: not('isPublic'),

  });

  _exports.default = _default;
});