define("skylines/controllers/groupflights/club", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'column', 'order'],
    page: 1,
    column: 'date',
    order: 'desc'
  });

  _exports.default = _default;
});