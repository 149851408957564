define("skylines/services/cesium-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CESIUM_BASE_URL = '/cesium/';

  var _default = Ember.Service.extend({
    loaderPromise: null,
    load: function load() {
      var promise = this.loaderPromise;

      if (!promise) {
        promise = new Ember.RSVP.Promise(function (resolve) {
          Ember.debug('Loading Cesium...');
          var cesium = document.createElement('script');
          cesium.src = "".concat(CESIUM_BASE_URL, "Cesium.js");
          cesium.onload = resolve;
          document.body.appendChild(cesium);
        });
        this.set('loaderPromise', promise);
      }

      return promise;
    }
  });

  _exports.default = _default;
});