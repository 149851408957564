define("skylines/helpers/percent", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.percent = percent;
  _exports.default = void 0;

  function percent(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        value = _ref2[0],
        max = _ref2[1];

    return Math.round(value * 100 / max);
  }

  var _default = Ember.Helper.helper(percent);

  _exports.default = _default;
});