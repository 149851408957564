define("skylines/routes/groupflights/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    account: Ember.inject.service(),
    ajax: Ember.inject.service() //  model() {
    //    let userId = this.get('account.user.id');
    //    if (userId) {
    //      return this.ajax.request('/api/settings');
    //    }
    //  },

  });

  _exports.default = _default;
});