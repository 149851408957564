define("skylines/components/x-pager", ["exports", "skylines/computed/safe-computed"], function (_exports, _safeComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    count: 0,
    page: 1,
    perPage: 100,
    firstPage: 1,
    pages: (0, _safeComputed.default)('count', 'perPage', function (count, perPage) {
      return Math.ceil(count / perPage);
    }),
    lastPage: Ember.computed.readOnly('pages'),
    prevPage: (0, _safeComputed.default)('page', 'firstPage', function (page, firstPage) {
      return Math.max(firstPage, page - 1);
    }),
    prevDisabled: (0, _safeComputed.default)('page', 'firstPage', function (page, firstPage) {
      return page <= firstPage;
    }),
    nextPage: (0, _safeComputed.default)('page', 'lastPage', function (page, lastPage) {
      return Math.min(lastPage, page + 1);
    }),
    nextDisabled: (0, _safeComputed.default)('page', 'lastPage', function (page, lastPage) {
      return page >= lastPage;
    }),
    showMinusFour: (0, _safeComputed.default)('page', 'firstPage', 'lastPage', function (page, firstPage, lastPage) {
      return page - 4 >= firstPage && page === lastPage;
    }),
    showMinusThree: (0, _safeComputed.default)('page', 'firstPage', 'lastPage', function (page, firstPage, lastPage) {
      return page - 3 >= firstPage && page >= lastPage - 1;
    }),
    showMinusTwo: (0, _safeComputed.default)('page', 'firstPage', function (page, firstPage) {
      return page - 2 >= firstPage;
    }),
    showMinusOne: (0, _safeComputed.default)('page', 'firstPage', function (page, firstPage) {
      return page - 1 >= firstPage;
    }),
    showPlusOne: (0, _safeComputed.default)('page', 'lastPage', function (page, lastPage) {
      return page + 1 <= lastPage;
    }),
    showPlusTwo: (0, _safeComputed.default)('page', 'lastPage', function (page, lastPage) {
      return page + 2 <= lastPage;
    }),
    showPlusThree: (0, _safeComputed.default)('page', 'lastPage', 'firstPage', function (page, lastPage, firstPage) {
      return page + 3 <= lastPage && page <= firstPage + 1;
    }),
    showPlusFour: (0, _safeComputed.default)('page', 'lastPage', 'firstPage', function (page, lastPage, firstPage) {
      return page + 4 <= lastPage && page === firstPage;
    })
  });

  _exports.default = _default;
});