define("skylines/components/wingman-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    nearFlights: null,
    visibleFlights: null,
    nearFlightsWithColors: Ember.computed('nearFlights.[]', 'visibleFlights.[]', function () {
      var nearFlights = this.nearFlights,
          visibleFlights = this.visibleFlights;
      return nearFlights.map(function (it) {
        var id = it.flight.id;
        var visibleFlight = visibleFlights.findBy('id', id);
        return {
          color: visibleFlight ? visibleFlight.get('color') : undefined,
          flight: it.flight,
          times: it.times
        };
      });
    }),
    actions: {
      select: function select(id) {
        this.onSelect(id);
      }
    }
  });

  _exports.default = _default;
});