define("skylines/templates/components/layer-switcher-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VJ1HTbuN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[11,\"class\",[28,\"if\",[[24,[\"layer\",\"visible\"]],\"active\"],null]],[10,\"style\",\"cursor: pointer;\"],[8],[0,\"\\n  \"],[7,\"img\",true],[11,\"src\",[22,\"imagePath\"]],[8],[9],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fa fa-check\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" \"],[1,[24,[\"layer\",\"name\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/layer-switcher-element.hbs"
    }
  });

  _exports.default = _default;
});