define("skylines/components/tracking-table-row", ["exports", "skylines/computed/safe-computed"], function (_exports, _safeComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    altitudeAGL: (0, _safeComputed.default)('track.altitude', 'track.elevation', function (altitude, elevation) {
      return Math.max(altitude - elevation, 0);
    })
  });

  _exports.default = _default;
});