define("skylines/templates/components/plane-label-overlays", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3Q3QLWsR",
    "block": "{\"symbols\":[\"fix\"],\"statements\":[[4,\"each\",[[24,[\"fixes\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"coordinate\"]]],null,{\"statements\":[[0,\"    \"],[5,\"plane-label-overlay\",[],[[\"@map\",\"@flight\",\"@position\"],[[22,\"map\"],[23,1,[\"flight\"]],[23,1,[\"coordinate\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/plane-label-overlays.hbs"
    }
  });

  _exports.default = _default;
});