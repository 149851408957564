define("skylines/components/timeline-event", ["exports", "skylines/computed/computed-component"], function (_exports, _computedComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    event: null,
    eventComponent: (0, _computedComponent.default)('event.type', 'timeline-events/')
  });

  _exports.default = _default;
});