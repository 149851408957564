define("skylines/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pe720ubZ",
    "block": "{\"symbols\":[],\"statements\":[[5,\"head-layout\",[],[[],[]]],[0,\"\\n\"],[1,[28,\"page-title\",[\"SkyLines\"],null],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"onFreestyleRoute\"]]],null,{\"statements\":[[0,\"  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"nav-bar\",[],[[\"@onSearch\"],[[28,\"action\",[[23,0,[]],\"search\"],null]]]],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/application.hbs"
    }
  });

  _exports.default = _default;
});