define("skylines/routes/tracking/map-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect: function redirect(_ref) {
      var user_ids = _ref.user_ids;
      this.transitionTo('tracking.details', user_ids);
    }
  });

  _exports.default = _default;
});