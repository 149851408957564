define("skylines/helpers/format-speed", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    units: Ember.inject.service(),
    speedUnitObserver: Ember.observer('units.speedUnit', function () {
      this.recompute();
    }),
    compute: function compute(_ref, options) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
          value = _ref2[0];

      return this.units.formatSpeed(value, options);
    }
  });

  _exports.default = _default;
});