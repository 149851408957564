define("skylines/controllers/flight/change-pilot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      transitionToFlight: function transitionToFlight() {
        this.transitionToRoute('flight', this.get('model.id'));
      }
    }
  });

  _exports.default = _default;
});