define("skylines/routes/club/pilots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model: function model() {
      var _this$paramsFor = this.paramsFor('club'),
          club_id = _this$paramsFor.club_id;

      return this.ajax.request("/api/users?club=".concat(club_id));
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('club', this.controllerFor('club').get('model'));
    }
  });

  _exports.default = _default;
});