define("skylines/components/x-flag", ["exports", "skylines/computed/safe-computed"], function (_exports, _safeComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    codeLower: (0, _safeComputed.default)('code', function (code) {
      return code.toLowerCase();
    })
  });

  _exports.default = _default;
});