define("skylines/utils/flight-phase", ["exports", "skylines/utils/next-smaller-index"], function (_exports, _nextSmallerIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    fixCalc: null,
    selection: null,
    flight: Ember.computed.readOnly('fixCalc.flights.firstObject'),
    coordinates: Ember.computed('flight', 'selection.{start,end}', function () {
      var selection = this.selection;

      if (!selection) {
        return;
      }

      var start = selection.start,
          end = selection.end;
      var flight = this.flight;
      var times = flight.get('time');
      var start_index = (0, _nextSmallerIndex.default)(times, start);
      var end_index = (0, _nextSmallerIndex.default)(times, end);

      if (start_index >= end_index) {
        return;
      }

      var coordinates = flight.get('geometry').getCoordinates();
      return coordinates.slice(start_index, end_index + 1);
    })
  });

  _exports.default = _default;
});