define("skylines/controllers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    account: Ember.inject.service(),
    notificationsTarget: Ember.computed('account.user', function () {
      return this.account.user ? 'notifications' : 'timeline';
    })
  });

  _exports.default = _default;
});