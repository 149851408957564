define("skylines/components/aircraft-model-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    models: null,
    modelId: null,
    modelsWithNull: Ember.computed('models.[]', function () {
      return [{
        id: null
      }].concat(this.models);
    }),
    model: Ember.computed('modelsWithNull.@each.id', 'modelId', function () {
      return this.modelsWithNull.findBy('id', this.modelId || null);
    }),
    actions: {
      onChange: function onChange(model) {
        this.onChange(model.id);
      }
    }
  });

  _exports.default = _default;
});