define("skylines/routes/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model: function model(_ref) {
      var user_id = _ref.user_id;
      return this.ajax.request("/api/users/".concat(user_id, "?extended"));
    }
  });

  _exports.default = _default;
});