define("skylines/components/flight-phase-row", ["exports", "skylines/computed/safe-computed"], function (_exports, _safeComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNames: ['selectable'],
    classNameBindings: ['selected'],
    phase: null,
    selection: null,
    onSelect: function onSelect() {},
    isCircling: Ember.computed.equal('phase.type', 'circling'),
    isPowered: Ember.computed.equal('phase.type', 'powered'),
    isCirclingLeft: Ember.computed.equal('phase.circlingDirection', 'left'),
    isCirclingRight: Ember.computed.equal('phase.circlingDirection', 'right'),
    glideRate: (0, _safeComputed.default)('phase.glideRate', function (gr) {
      return Math.abs(gr) > 1000 ? Infinity : gr;
    }),
    selected: (0, _safeComputed.default)('selection', function (selection) {
      var phase = this.phase;
      return selection.start === phase.secondsOfDay && selection.end === phase.secondsOfDay + phase.duration;
    }),
    click: function click() {
      var onSelect = this.onSelect;

      if (this.selected) {
        onSelect(null);
      } else {
        var phase = this.phase;
        onSelect({
          start: phase.secondsOfDay,
          end: phase.secondsOfDay + phase.duration
        });
      }
    }
  });

  _exports.default = _default;
});