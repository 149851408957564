define("skylines/controllers/timeline", ["exports", "skylines/computed/safe-computed"], function (_exports, _safeComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'user', 'type'],
    page: 1,
    user: null,
    type: null,
    events: Ember.computed.alias('model.events'),
    prevPage: (0, _safeComputed.default)('page', function (page) {
      if (page > 1) {
        return page - 1;
      }
    }),
    nextPage: (0, _safeComputed.default)('page', 'events.length', 'perPage', function (page, numEvents, perPage) {
      if (numEvents === perPage) {
        return page + 1;
      }
    })
  });

  _exports.default = _default;
});