define("skylines/templates/components/circling-performance-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8/4sT9fM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"table\",true],[10,\"class\",\"table table-condensed\"],[8],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n  \"],[7,\"tr\",true],[8],[0,\"\\n    \"],[7,\"th\",true],[8],[9],[0,\"\\n    \"],[7,\"th\",true],[10,\"class\",\"numeric\"],[8],[1,[28,\"t\",[\"count\"],null],false],[7,\"br\",true],[8],[9],[1,[28,\"t\",[\"avg-vario-abbr\"],null],false],[9],[0,\"\\n    \"],[7,\"th\",true],[10,\"class\",\"numeric\"],[8],[1,[28,\"t\",[\"duration\"],null],false],[7,\"br\",true],[8],[9],[0,\"ΔH\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[4,\"if\",[[24,[\"total\"]]],null,{\"statements\":[[5,\"circling-performance-row\",[],[[\"@perf\"],[[22,\"total\"]]]]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"if\",[[24,[\"left\"]]],null,{\"statements\":[[5,\"circling-performance-row\",[],[[\"@perf\"],[[22,\"left\"]]]]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"if\",[[24,[\"right\"]]],null,{\"statements\":[[5,\"circling-performance-row\",[],[[\"@perf\"],[[22,\"right\"]]]]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"if\",[[24,[\"mixed\"]]],null,{\"statements\":[[5,\"circling-performance-row\",[],[[\"@perf\"],[[22,\"mixed\"]]]]],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "skylines/templates/components/circling-performance-table.hbs"
    }
  });

  _exports.default = _default;
});