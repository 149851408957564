define("skylines/routes/user/following", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model: function model() {
      var _this$paramsFor = this.paramsFor('user'),
          user_id = _this$paramsFor.user_id;

      return this.ajax.request("/api/users/".concat(user_id, "/following"));
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('user', this.modelFor('user'));
      controller.set('following', model.following);
    }
  });

  _exports.default = _default;
});