define("skylines/routes/statistics/airport", ["exports", "skylines/routes/statistics/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    getURL: function getURL(_ref) {
      var airport_id = _ref.airport_id;
      return "/api/statistics/airport/".concat(airport_id);
    }
  });

  _exports.default = _default;
});