define("skylines/helpers/html-safe", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.htmlSafe = htmlSafe;
  _exports.default = void 0;

  function htmlSafe(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        text = _ref2[0];

    return Ember.String.htmlSafe(text);
  }

  var _default = Ember.Helper.helper(htmlSafe);

  _exports.default = _default;
});