define("skylines/controllers/stats/wildcard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    account: Ember.inject.service(),
    name: Ember.computed.readOnly('model.name'),
    years: Ember.computed.readOnly('model.years'),
    sumPilots: Ember.computed.readOnly('model.sumPilots')
  });

  _exports.default = _default;
});