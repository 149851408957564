define("skylines/routes/groupflights/date", ["exports", "skylines/routes/groupflights/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    getURL: function getURL(_ref) {
      var date = _ref.date;
      return "/api/groupflights/date/".concat(date);
    }
  });

  _exports.default = _default;
});