define("skylines/controllers/groupflight/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['baselayer', 'overlays'],
    baselayer: null,
    overlays: null,
    actions: {
      transitionTo: function transitionTo() {
        this.transitionToRoute.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});