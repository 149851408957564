define("skylines/components/flight-path-layer-feature", ["exports", "openlayers"], function (_exports, _openlayers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    source: null,
    flight: null,
    feature: Ember.computed(function () {
      var flight = this.flight;
      return new _openlayers.default.Feature({
        geometry: flight.get('geometry'),
        sfid: flight.get('id'),
        color: flight.get('color'),
        type: 'flight'
      });
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.source.addFeature(this.feature);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.source.removeFeature(this.feature);
    }
  });

  _exports.default = _default;
});