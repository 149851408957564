define("skylines/components/timeline-events/new-user", ["exports", "skylines/components/timeline-events/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    translationKey: Ember.computed('accountUserIsActor', function () {
      var i = this.accountUserIsActor ? 2 : 1;
      return "timeline-events.new-user.message".concat(i);
    })
  });

  _exports.default = _default;
});