define("skylines/services/map-settings", ["exports", "skylines/utils/parse-query-string"], function (_exports, _parseQueryString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.OVERLAY_LAYERS_COOKIE_KEY = _exports.BASE_LAYER_COOKIE_KEY = void 0;
  var BASE_LAYER_COOKIE_KEY = 'base_layer';
  _exports.BASE_LAYER_COOKIE_KEY = BASE_LAYER_COOKIE_KEY;
  var OVERLAY_LAYERS_COOKIE_KEY = 'overlay_layers';
  _exports.OVERLAY_LAYERS_COOKIE_KEY = OVERLAY_LAYERS_COOKIE_KEY;

  var _default = Ember.Service.extend({
    cookies: Ember.inject.service(),
    router: Ember.inject.service(),
    _baseLayer: 'OpenStreetMap',
    // _overlayLayers: ['Airspace'],
    baseLayer: Ember.computed.or('_query.baselayer', '_baseLayer'),
    overlayLayers: Ember.computed('_query.overlays', '_overlayLayers', function () {
      var queryOverlays = this.get('_query.overlays');

      if (queryOverlays === undefined) {
        return this._overlayLayers;
      } else if (queryOverlays === '') {
        return [];
      } else {
        return queryOverlays.split(';');
      }
    }),
    _query: Ember.computed('router.currentURL', function () {
      var currentURL = this.get('router.currentURL');
      var queryString = extractQueryString(currentURL);
      return (0, _parseQueryString.default)(queryString);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('_overlayLayers', ['Airspace']);
      var cookies = this.cookies;
      var cookieBaseLayer = cookies.read(BASE_LAYER_COOKIE_KEY);

      if (cookieBaseLayer) {
        this.set('_baseLayer', cookieBaseLayer);
      }

      var cookieOverlayLayers = cookies.read(OVERLAY_LAYERS_COOKIE_KEY);

      if (cookieOverlayLayers !== undefined) {
        this.set('_overlayLayers', cookieOverlayLayers === '' ? [] : cookieOverlayLayers.split(';'));
      }
    },
    isLayerVisible: function isLayerVisible(layer) {
      return this.baseLayer === layer || this.overlayLayers.includes(layer);
    },
    setBaseLayer: function setBaseLayer(baseLayer) {
      this.set('_baseLayer', baseLayer);
      this.cookies.write(BASE_LAYER_COOKIE_KEY, baseLayer, {
        path: '/',
        expires: new Date('2099-12-31')
      });
    },
    toggleOverlayLayer: function toggleOverlayLayer(overlayLayer) {
      var overlayLayers = this.overlayLayers;

      if (overlayLayers.includes(overlayLayer)) {
        overlayLayers.removeObject(overlayLayer);
      } else {
        overlayLayers.pushObject(overlayLayer);
      }

      this.cookies.write(OVERLAY_LAYERS_COOKIE_KEY, overlayLayers.join(';'), {
        path: '/',
        expires: new Date('2099-12-31')
      });
    }
  });

  _exports.default = _default;

  function extractQueryString(url) {
    var qIndex = url.indexOf('?');

    if (qIndex === -1) {
      return null;
    }

    return url.slice(qIndex + 1);
  }
});